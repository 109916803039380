import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { DataService } from '../data.service';
import { Feedback } from '../feedback';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location } from '@angular/common';
import { faSmile } from '@fortawesome/free-regular-svg-icons';
import { faFrown } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})

export class SurveyComponent implements OnInit {

  public answers: any[] = [];

  surveyQuestion3Message: string;

  dateToday = new Date();
  serviceOrderNumber = '';
  CSR_ID = '';
  CSRName = '';
  questions: any;
  questionsLoop: any;

  BRANCH_ID = 0;
  BranchName = '';
  branchState = '';
  @ViewChild('successContent') successModal;

  submitted = false;
  surveyForm: FormGroup;

  faSmile = faSmile;
  faFrown = faFrown;

  reasons: any[] = [];

  checkedCheckboxes: any[] = [];

  showAlert = false;

  COMPANY_ID = 56;

  constructor(private formBuilder: FormBuilder, private dataService: DataService, private router: Router, private ngxLoader: NgxUiLoaderService, private location: Location, private el: ElementRef) { }

  async ngOnInit() {
    this.surveyForm = this.formBuilder.group({
      orderNumber: [
        '',
      ],
      visitorName: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ],
      ],
    });
    this.ngxLoader.start();



    const url_string = window.location.href;
    const url = new URL(url_string);
    this.CSR_ID = url.searchParams.get('c');
    this.BRANCH_ID = Number(url.searchParams.get('b'));
    this.COMPANY_ID = Number(url.searchParams.get('i')) === 0 ? 56 : Number(url.searchParams.get('i'));

    if (this.CSR_ID == null || this.BRANCH_ID == null) {
      this.ngxLoader.stop();
      this.router.navigate(['/something-wrong', 'EMPTY']);
    }

    this.location.replaceState('');

    await this.getQuestions();

    // alert(this.COMPANY_ID);
    this.dataService.getBranchDetails(this.COMPANY_ID, this.BRANCH_ID).subscribe(
      branchResponse => {
        if (branchResponse && Object.keys(branchResponse).length > 0) {
          this.BranchName = branchResponse.BRANCH_NAME;
          this.branchState = branchResponse.STATE_PROVINCE;
          this.dataService.getCSRDetails(this.CSR_ID, this.COMPANY_ID, this.BRANCH_ID).subscribe(
            csrResponse => {
              // // console.log('csrResponse', csrResponse);
              if (csrResponse && Object.keys(csrResponse).length > 0) {
                this.CSRName = csrResponse.fla.FULLNAME;
                const status = csrResponse.fla.STATUS.toUpperCase();
                // console.log(status);
                if (status === 'INACTIVE') {
                  this.router.navigate(['/something-wrong', 'INACTIVE'], { replaceUrl: true });
                }
              } else {
                this.router.navigate(['/something-wrong', 'EMPTY'], { replaceUrl: true });
              }
              this.ngxLoader.stop();
            },
            err => {
              // console.log(err);
              this.ngxLoader.stop();
            }
          );
        }
        else {
          this.router.navigate(['/something-wrong', 'EMPTY'], { replaceUrl: true });
          this.ngxLoader.stop();
        }
      },
      err => {
        // console.log(err);
        this.ngxLoader.stop();
        this.router.navigate(['/something-wrong', 'ERROR'], { replaceUrl: true });
      }
    );
  }



  async getQuestions(): Promise<void> {
    await this.dataService.getQuestions().subscribe(
      response => {
        try {
          this.questions = response;
          // TODO: Remove when not staging anymore
          // this.questions = this.questions.filter(q => q.id !== 1);
          this.questionsLoop = response;
          for (const question of this.questionsLoop) {
            if (question.Branches == null) {
              if (question.IsFreeForm === 6) {
                this.answers.push({
                  answer: '',
                  id: question.id,
                  yesNo: ''
                });
              } else  {
                this.answers.push({
                  answer: '',
                  id: question.id,
                });
              }
            } else {
              try {
                const questionBranches = JSON.parse(question.Branches).ids;
                if (questionBranches.includes(this.BRANCH_ID)) {
                  if (question.IsFreeForm === 6) {
                    this.answers.push({
                      answer: '',
                      id: question.id,
                      yesNo: ''
                    });
                  } else  {
                    this.answers.push({
                      answer: '',
                      id: question.id,
                    });
                  }
                } else {
                  this.questions = this.questions.filter(x => {
                    if (x.Branches != null) {
                      const qb = JSON.parse(question.Branches).ids;
                      return qb.includes(this.BRANCH_ID);
                    } else {
                      return true;
                    }
                  });
                }
              } catch (error) {
                // console.log(error);
              }
            }
            console.log(this.answers);
          }
        } catch (e) {
          // console.log(e);
        }
      },
      err => {
        console.log(err);
        this.ngxLoader.stop();
      }
    );
  }

  get sf() {
    return this.surveyForm.controls;
  }

  onSubmit(): void {
    console.log(this.answers);
    for (const answer of this.answers) {
      if (answer.yesNo === 2) {
        continue;
      }
      if (answer.answer === '' ) {
        this.showAlert = true;
        this.findInvalidControls();
        return;
      }
    }

    const invalidControl = this.el.nativeElement.querySelector('#visitorName.ng-invalid');
    this.submitted = true;
    // console.log(this.surveyForm.get('mobileNumber').errors);
    if (invalidControl) {
      console.log(invalidControl);
      invalidControl.focus();
    }
    if (this.surveyForm.invalid) {
      console.log('trains');
      this.findInvalidControls();
      return;
    }
    this.submitSurvey();
  }

  submitSurvey(): void {
    this.showAlert = false;
    this.ngxLoader.start();
    const questionObject: any = [];
    for (const question of this.questions) {
      const theAnswer = this.answers.find(answer => answer.id === question.id);
      if (question.IsFreeForm === 6 && theAnswer.yesNo !== 1) {
        theAnswer.answer = 2;
      }
      questionObject.push({
        id: question.id,
        answer: theAnswer.answer
      });
    }

    const feedback: Feedback = {
      ServiceOrder: this.surveyForm.get('orderNumber').value,
      VisitDate: new Date(),
      Branch: this.BRANCH_ID,
      CSRName: this.CSR_ID.toString(),
      Feedback: JSON.stringify(questionObject),
      VisitorName: this.surveyForm.get('visitorName').value,
      Created_by: 1,
      Region: this.branchState,
      Reasons: JSON.stringify(this.reasons)
    };

    this.dataService.addFeedback(feedback).subscribe(
      response => {
        // console.log(response);
        this.ngxLoader.stop();
        this.router.navigate(['/success']);
      },
      err => {
        // console.log(err);
        this.ngxLoader.stop();
      }
    );
  }

  findInvalidControls(): void {
    const invalid = [];
    const controls = this.surveyForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    console.log(invalid);
  }

}
