import {Component, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {DataService} from '../data.service';
import {Branch} from '../branch';
import {Csr} from '../csr';
import {Question} from '../question';
import {Location} from '@angular/common';
import {SessionStorageService} from 'ngx-webstorage';
import {Router} from '@angular/router';
import {Workbook} from 'exceljs';
import * as fs from 'file-saver';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import { ChartOptions, ChartType, Chart} from 'chart.js';
import {Label} from 'ng2-charts';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-feedback-table',
  templateUrl: './feedback-table.component.html',
  styleUrls: ['./feedback-table.component.scss']
})


export class FeedbackTableComponent implements OnInit {
  @ViewChildren('type5ChartTag') type5ChartsView;
  @ViewChild('overviewContent') overviewModal;


  surveyModalResults: any;
  questionModal: string;
  answerModal: string;

  type5Charts: Chart[] = [];

  timeFreeKey;
  username;
  accessList: any[] = [];
  accessLevel = 0;
  branchAccessIds: number[] = [];

  csrs: Csr[] = [];
  branches: Branch[] = [];
  questions: Question[] = [];

  tableSelect = 'tally';

  branchSelect: any;
  csrSelect: any;
  regionSelect: any;

  surveyData: any;
  csrData: any;
  surveyTally: any;

  csrDisplayData: any[] = [];
  branchDisplayData: any[] = [];
  regionDisplayData: any[] = [];
  overviewDisplayData: any[] = [];
  allBranchDisplayData: any[] = [];

  regions: string[] = [];

  startDateFilter: Date = new Date();
  endDateFilter: Date = new Date();

  csvString = '';
  isCsvButtonDisabled = true;

  initialBranch = 0;

  barChartOptions: ChartOptions = {
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 20,
          stepSize: 10 // this worked as expected
        }
      }]
    },
    onClick: (e, a) => this.graphClickEvent(e, a)



    // responsive: true,
    // legend: {
    //   labels: {
    //     // This more specific font property overrides the global property
    //     fontSize: 14
    //   }
    // }
  };

  // OVERVIEW VARIABLES

  barChartLabels: Label[] = ['Question 1'];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];
  type5BarChartData: any[] = [];
  type2BarChartData: any[] = [];
  type6BarChartData: any[] = [];

  constructor(private dataService: DataService, private sessionStorage: SessionStorageService, private location: Location, private router: Router, private ngxLoader: NgxUiLoaderService, private modalService: NgbModal) {
  }


  groupArrayOfObjects(list, key): any {
    return list.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }


  ngOnInit(): void {
    this.ngxLoader.start();
    const urlString = window.location.href;
    const url = new URL(urlString);
    this.timeFreeKey = url.searchParams.get('k');
    const usernameParams = url.searchParams.get('i');
    const branchID = Number(url.searchParams.get('b'));
    this.initialBranch = branchID;
    // console.log(this.initialBranch);
    // console.log(usernameParams);
    // console.log(this.timeFreeKey);
    if (usernameParams != null && this.timeFreeKey === '4483A15EA95D7715958F6BD2256E2') {
      this.sessionStorage.store('username', usernameParams);
      this.sessionStorage.store('branch', branchID);
      this.username = usernameParams;
    } else if (this.sessionStorage.retrieve('username') != null) {

      this.username = this.sessionStorage.retrieve('username');
      this.initialBranch = this.sessionStorage.retrieve('branch');
      // console.log(this.username);
      // console.log(this.sessionStorage.retrieve('branch'));
    } else {
      this.router.navigate(['something-wrong']);
    }
    this.location.replaceState('/reports');

    this.dataService.getUserAccess(this.username).subscribe(response => {
      const responseData = response;
      this.accessList = responseData.ResponseObject.accessList;
      this.accessLevel = responseData.ResponseObject.user.accesslevel;
      for (const accessElement of this.accessList) {
        this.branchAccessIds.push(accessElement.access.branchID);
      }
      this.dataService.getAllBranchesByCompanyId(56).subscribe(response => {
        const branchResponses = response;
        // console.log(branchResponses);
        this.regions = [...new Set(branchResponses.map(item => item.STATE_PROVINCE))] as any;
        // console.log(this.regions)
        // this.regions.splice(0, 0, 'ALL');

        const filteredBranches = branchResponses.filter(branchResponse => {
          return this.branchAccessIds.indexOf(branchResponse.BRANCH_NO) !== -1;
        });
        for (const branchResponse of filteredBranches) {
          this.branches.push({id: branchResponse.BRANCH_NO, BRANCH_NAME: branchResponse.BRANCH_NAME});
        }
        this.branches.sort((a, b) => (a.BRANCH_NAME > b.BRANCH_NAME) ? 1 : ((b.BRANCH_NAME > a.BRANCH_NAME) ? -1 : 0));
        this.branchSelect = this.initialBranch;
        // if (branchID === 0) {
        //   this.branchSelect = this.branches[0].id;
        // } else {
        //   this.branchSelect = branchID;
        // }
        this.selectBranch(this.branchSelect);
        this.csrSelect = 'ALL';
        this.getBranchTally();
        this.ngxLoader.stop();
      }, errors => console.log(errors));
      this.dataService.getCSRSurveyByRegion('Metro Manila', moment(this.startDateFilter).utc().format('YYYY-MM-DD'), moment(this.endDateFilter).utc().format('YYYY-MM-DD')).subscribe(response => {
        // console.log(response);
      }, errors => console.log(errors));
    }, userAccessError => console.log(userAccessError));
    this.startDateFilter.setMonth(this.startDateFilter.getMonth() - 12);

  }

  openClickedOverviewSurveyResults() {
    this.modalService.open(this.overviewModal, {ariaLabelledBy: 'modal-basic-title', size: 'lg'});
  }

  checkKeypress(event): boolean {
    return !(event.key !== 'ArrowDown' || event.key !== 'ArrowRight' || event.key !== 'ArrowLeft' || event.key !== 'ArrowUp');
  }

  graphClickEvent(event, active): void {
    this.dataService.getQuestions().subscribe(
      questionResponse => {
        this.questions = questionResponse;
        if (this.branchSelect !== 'ALL') {
          this.questions = this.questions.filter(x => {
            return this.questionBranchFilter(x);
          });
        }
      });
    Chart.helpers.each(Chart.instances, (instance) => {
      if (instance.chart.id === active[0]._chart.id) {
        console.log(instance);
        const element = instance.getElementAtEvent(event);
        if (element.length) {
          console.log(element[0]._model.datasetLabel);
          console.log(element[0]._model.label);
          if (this.branchSelect === 'ALL') {
            const selectedSurveys: any[] = [];
            for (const branch of this.branches) {
              this.dataService.getCSRSurveyByBranch(branch.id.toString(), moment(this.startDateFilter).utc().format('YYYY-MM-DD'), moment(this.endDateFilter).utc().format('YYYY-MM-DD')).subscribe(
                surveyResonse => {
                  const question = this.questions.find(x => x.Question === element[0]._model.label);
                  console.log(this.questions);
                  console.log(element[0]._model.label);
                  const answerString = element[0]._model.datasetLabel;
                  let answerID;

                  if (question.IsFreeForm === 5) {
                    if (answerString === 'Very Satisfied') {
                      answerID = 2;
                    } else if (answerString === 'Satisfied') {
                      answerID = 1;
                    } else if (answerString === 'Neutral') {
                      answerID = 0;
                    } else if (answerString === 'Dissatisfied') {
                      answerID = -1;
                    } else if (answerString === 'Very Dissatisfied') {
                      answerID = -2;
                    }
                  } else if (question.IsFreeForm === 2) {
                    if (answerString === 'Yes') {
                      answerID = 2;
                    } else if (answerString === 'No') {
                      answerID = 1;
                    }
                  }
                  const surveyArray = surveyResonse;
                  const filteredSurveyArray = surveyArray.filter(x => {
                    const feedback = JSON.parse(x.Feedback);
                    return feedback.some(y => y.id === question.id && y.answer === answerID);
                  });
                  filteredSurveyArray.forEach(z => selectedSurveys.push(z));
                  this.surveyModalResults = selectedSurveys;
                  this.questionModal = element[0]._model.label;
                  this.answerModal = element[0]._model.datasetLabel;

                });
            }
          } else {
            const selectedSurveys: any[] = [];
            this.dataService.getCSRSurveyByBranch(this.branchSelect.toString(), moment(this.startDateFilter).utc().format('YYYY-MM-DD'), moment(this.endDateFilter).utc().format('YYYY-MM-DD')).subscribe(
              surveyResonse => {
                const question = this.questions.find(x => x.Question === element[0]._model.label);
                console.log(this.questions);
                console.log(element[0]._model.label);
                const answerString = element[0]._model.datasetLabel;
                let answerID;

                if (question.IsFreeForm === 5) {
                  if (answerString === 'Very Satisfied') {
                    answerID = 2;
                  } else if (answerString === 'Satisfied') {
                    answerID = 1;
                  } else if (answerString === 'Neutral') {
                    answerID = 0;
                  } else if (answerString === 'Dissatisfied') {
                    answerID = -1;
                  } else if (answerString === 'Very Dissatisfied') {
                    answerID = -2;
                  }
                } else if (question.IsFreeForm === 2) {
                  if (answerString === 'Yes') {
                    answerID = 2;
                  } else if (answerString === 'No') {
                    answerID = 1;
                  }
                }
                const surveyArray = surveyResonse;
                const filteredSurveyArray = surveyArray.filter(x => {
                  const feedback = JSON.parse(x.Feedback);
                  return feedback.some(y => y.id === question.id && y.answer === answerID);
                });
                filteredSurveyArray.forEach(z => selectedSurveys.push(z));
                this.surveyModalResults = selectedSurveys;
                this.questionModal = element[0]._model.label;
                this.answerModal = element[0]._model.datasetLabel;

              });
          }
        }
      }
    });
    this.openClickedOverviewSurveyResults();
  }

  reportTypeChange(): void {
    if (this.tableSelect === 'branchTally') {
      this.branchSelect = this.initialBranch;
      this.getBranchTally();
    } else if (this.tableSelect === 'overview') {
      this.branchSelect = 'ALL';
      this.getOverview();
    } else if (this.tableSelect === 'raw' || this.tableSelect === 'tally') {
      this.branchSelect = this.initialBranch;
      this.selectCsr('ALL');
    } else if (this.tableSelect === 'regionTally') {
      this.regionSelect = this.regions[1];
      this.getRegionTally();
    }
  }

  getRegionTally(): void {
    this.regionDisplayData = [];
    console.log(this.allBranchDisplayData);
    this.csrSelect = 'ALL';

    this.dataService.getQuestions().subscribe(
      questionResponse => {
        this.ngxLoader.start();
        this.questions = questionResponse;
        this.questions = this.questions.filter(x => {
          return this.questionBranchFilter(x);
        });
        this.dataService.getCSRSurveyByRegion
        (this.regionSelect, moment(this.startDateFilter).utc().format('YYYY-MM-DD'), moment(this.endDateFilter).utc().format('YYYY-MM-DD')).subscribe(
          surveyResonse => {
            this.surveyData = surveyResonse;
            const regionDisplayObject = {
              RegionName: this.regionSelect,
              // BranchID: branch.id,
              tally: [],
            };
            for (const feedback of this.surveyData) {
              const feedbackArray = JSON.parse(feedback.Feedback);
              for (const question of this.questions) {
                const answer = feedbackArray.find(feedbackObject => feedbackObject.id === question.id);
                if (answer != null) {
                  if (question.IsFreeForm > 0) {
                    if (regionDisplayObject.tally.length === 0) { // empty
                      if (question.IsFreeForm === 6 && answer.answer !== 2) {
                        regionDisplayObject.tally.push({
                          id: question.id,
                          question: question.Question,
                          answer: [{
                            rating: 1,
                            count: 1,
                            yesNo: [answer.answer]
                          }],
                          isFreeForm: question.IsFreeForm
                        });

                      } else {
                        regionDisplayObject.tally.push({
                          id: question.id,
                          question: question.Question,
                          answer: [{
                            rating: answer.answer,
                            count: 1
                          }],
                          isFreeForm: question.IsFreeForm
                        });
                      }
                    } else if (!regionDisplayObject.tally.some(tallyElement => tallyElement.id === question.id)) { // new question
                      if (question.IsFreeForm === 6 && answer.answer !== 2) {
                        regionDisplayObject.tally.push({
                          id: question.id,
                          question: question.Question,
                          answer: [{
                            rating: 1,
                            count: 1,
                            yesNo: [answer.answer]
                          }],
                          isFreeForm: question.IsFreeForm
                        });
                      } else {
                        regionDisplayObject.tally.push({
                          id: question.id,
                          question: question.Question,
                          answer: [{
                            rating: answer.answer,
                            count: 1
                          }],
                          isFreeForm: question.IsFreeForm
                        });
                      }
                    } else { // existing
                      if (question.IsFreeForm === 6 && answer.answer !== 2) {
                        const tempTally = regionDisplayObject.tally.find(tallyElement => tallyElement.id === question.id);
                        if (tempTally.answer.some(sumElement => sumElement.rating === 1)) {
                          const tempSum = tempTally.answer.find(sumElement => sumElement.rating === 1);
                          tempSum.count++;
                          if (typeof tempSum.yesNo === 'string') {
                            tempSum.yesNo = [tempSum.yesNo, answer.answer];
                          } else {
                            tempSum.yesNo.push(answer.answer);
                          }

                        } else {
                          tempTally.answer.push({
                            rating: 1,
                            count: 1,
                            yesNo: answer.answer
                          });

                        }
                      } else {
                        const tempTally = regionDisplayObject.tally.find(tallyElement => tallyElement.id === question.id);
                        if (tempTally.answer.some(sumElement => sumElement.rating === answer.answer)) {
                          const tempSum = tempTally.answer.find(sumElement => sumElement.rating === answer.answer);
                          tempSum.count++;

                        } else {
                          tempTally.answer.push({
                            rating: answer.answer,
                            count: 1
                          });
                        }
                      }
                    }
                  } else {
                    if (regionDisplayObject.tally.length === 0) {
                      regionDisplayObject.tally.push({
                        id: question.id,
                        question: question.Question,
                        answer: [answer.answer],
                        isFreeForm: question.IsFreeForm
                      });
                    } else if (!regionDisplayObject.tally.some(tallyElement => tallyElement.id === question.id)) {
                      regionDisplayObject.tally.push({
                        id: question.id,
                        question: question.Question,
                        answer: [answer.answer],
                        isFreeForm: question.IsFreeForm
                      });
                    } else {
                      const tempTally = regionDisplayObject.tally.find(tallyElement => tallyElement.id === question.id);
                      tempTally.answer.push(answer.answer);
                    }
                  }
                }
              }
            }
            this.regionDisplayData.push(regionDisplayObject);
            this.ngxLoader.stop();
          },
          surveyError => {
            console.log(surveyError);
            // this.ngxLoader.stop();
          });

      },
      errorLAST => {
        console.log(errorLAST);
        // this.ngxLoader.stop();
      }
    );


  }

  getBranchTally(): void {
    this.branchDisplayData = [];
    // console.log(this.allBranchDisplayData);
    this.csrSelect = 'ALL';
    if (this.branchSelect === 'ALL') {
      for (const branch of this.branches) {
        this.dataService.getQuestions().subscribe(
          questionResponse => {
            this.ngxLoader.start();
            this.questions = questionResponse;
            this.questions = this.questions.filter(x => {
              return this.questionBranchFilter(x);
            });
            this.dataService.getCSRSurveyByBranch
            (branch.id.toString(), moment(this.startDateFilter).utc().format('YYYY-MM-DD'), moment(this.endDateFilter).utc().format('YYYY-MM-DD')).subscribe(
              surveyResonse => {
                this.surveyData = surveyResonse;
                const branchDisplayObject = {
                  BranchName: branch.BRANCH_NAME,
                  BranchID: branch.id,
                  tally: [],
                };
                for (const feedback of this.surveyData) {
                  const feedbackArray = JSON.parse(feedback.Feedback);
                  for (const question of this.questions) {
                    const answer = feedbackArray.find(feedbackObject => feedbackObject.id === question.id);
                    if (answer != null) {
                      if (question.IsFreeForm > 0) {
                        if (branchDisplayObject.tally.length === 0) { // empty
                          if (question.IsFreeForm === 6 && answer.answer !== 2) {
                            branchDisplayObject.tally.push({
                              id: question.id,
                              question: question.Question,
                              answer: [{
                                rating: 1,
                                count: 1,
                                yesNo: [answer.answer]
                              }],
                              isFreeForm: question.IsFreeForm
                            });

                          } else {
                            branchDisplayObject.tally.push({
                              id: question.id,
                              question: question.Question,
                              answer: [{
                                rating: answer.answer,
                                count: 1
                              }],
                              isFreeForm: question.IsFreeForm
                            });
                          }
                        } else if (!branchDisplayObject.tally.some(tallyElement => tallyElement.id === question.id)) { // new question
                          if (question.IsFreeForm === 6 && answer.answer !== 2) {
                            branchDisplayObject.tally.push({
                              id: question.id,
                              question: question.Question,
                              answer: [{
                                rating: 1,
                                count: 1,
                                yesNo: [answer.answer]
                              }],
                              isFreeForm: question.IsFreeForm
                            });
                          } else {
                            branchDisplayObject.tally.push({
                              id: question.id,
                              question: question.Question,
                              answer: [{
                                rating: answer.answer,
                                count: 1
                              }],
                              isFreeForm: question.IsFreeForm
                            });
                          }
                        } else { // existing
                          if (question.IsFreeForm === 6 && answer.answer !== 2) {
                            const tempTally = branchDisplayObject.tally.find(tallyElement => tallyElement.id === question.id);
                            if (tempTally.answer.some(sumElement => sumElement.rating === 1)) {
                              const tempSum = tempTally.answer.find(sumElement => sumElement.rating === 1);
                              tempSum.count++;
                              if (typeof tempSum.yesNo === 'string') {
                                tempSum.yesNo = [tempSum.yesNo, answer.answer];
                              } else {
                                tempSum.yesNo.push(answer.answer);
                              }

                            } else {
                              tempTally.answer.push({
                                rating: 1,
                                count: 1,
                                yesNo: answer.answer
                              });

                            }
                          } else {
                            const tempTally = branchDisplayObject.tally.find(tallyElement => tallyElement.id === question.id);
                            if (tempTally.answer.some(sumElement => sumElement.rating === answer.answer)) {
                              const tempSum = tempTally.answer.find(sumElement => sumElement.rating === answer.answer);
                              tempSum.count++;

                            } else {
                              tempTally.answer.push({
                                rating: answer.answer,
                                count: 1
                              });
                            }
                          }
                        }
                      } else {
                        if (branchDisplayObject.tally.length === 0) {
                          branchDisplayObject.tally.push({
                            id: question.id,
                            question: question.Question,
                            answer: [answer.answer],
                            isFreeForm: question.IsFreeForm
                          });
                        } else if (!branchDisplayObject.tally.some(tallyElement => tallyElement.id === question.id)) {
                          branchDisplayObject.tally.push({
                            id: question.id,
                            question: question.Question,
                            answer: [answer.answer],
                            isFreeForm: question.IsFreeForm
                          });
                        } else {
                          const tempTally = branchDisplayObject.tally.find(tallyElement => tallyElement.id === question.id);
                          tempTally.answer.push(answer.answer);
                        }
                      }
                    }
                  }
                }
                this.branchDisplayData.push(branchDisplayObject);
                this.ngxLoader.stop();
              },
              surveyError => {
                console.log(surveyError);
                this.ngxLoader.stop();
              });

          },
          errorLAST => {
            console.log(errorLAST);
            this.ngxLoader.stop();
          }
        );
      }
    } else {
      this.dataService.getQuestions().subscribe(
        questionResponse => {
          this.ngxLoader.start();
          this.questions = questionResponse;
          this.questions = this.questions.filter(x => {
            return this.questionBranchFilter(x);
          });
          this.dataService.getCSRSurveyByBranch
          (this.branchSelect.toString(), moment(this.startDateFilter).utc().format('YYYY-MM-DD'), moment(this.endDateFilter).utc().format('YYYY-MM-DD')).subscribe(
            surveyResonse => {
              this.surveyData = surveyResonse;

              const branchName = this.branches.filter(x => x.id === this.branchSelect)[0].BRANCH_NAME;
              const branchDisplayObject = {
                BranchName: branchName.length > 0 ? branchName : '',
                BranchID: this.branchSelect,
                tally: [],
              };
              for (const feedback of this.surveyData) {
                const feedbackArray = JSON.parse(feedback.Feedback);
                for (const question of this.questions) {
                  const answer = feedbackArray.find(feedbackObject => feedbackObject.id === question.id);
                  if (answer != null) {
                    if (question.IsFreeForm > 0) {
                      if (branchDisplayObject.tally.length === 0) { // empty
                        if (question.IsFreeForm === 6 && answer.answer !== 2) {
                          branchDisplayObject.tally.push({
                            id: question.id,
                            question: question.Question,
                            answer: [{
                              rating: 1,
                              count: 1,
                              yesNo: [answer.answer]
                            }],
                            isFreeForm: question.IsFreeForm
                          });

                        } else {
                          branchDisplayObject.tally.push({
                            id: question.id,
                            question: question.Question,
                            answer: [{
                              rating: answer.answer,
                              count: 1
                            }],
                            isFreeForm: question.IsFreeForm
                          });
                        }
                      } else if (!branchDisplayObject.tally.some(tallyElement => tallyElement.id === question.id)) { // new question
                        if (question.IsFreeForm === 6 && answer.answer !== 2) {
                          branchDisplayObject.tally.push({
                            id: question.id,
                            question: question.Question,
                            answer: [{
                              rating: 1,
                              count: 1,
                              yesNo: [answer.answer]
                            }],
                            isFreeForm: question.IsFreeForm
                          });
                        } else {
                          branchDisplayObject.tally.push({
                            id: question.id,
                            question: question.Question,
                            answer: [{
                              rating: answer.answer,
                              count: 1
                            }],
                            isFreeForm: question.IsFreeForm
                          });
                        }
                      } else { // existing
                        if (question.IsFreeForm === 6 && answer.answer !== 2) {
                          const tempTally = branchDisplayObject.tally.find(tallyElement => tallyElement.id === question.id);
                          if (tempTally.answer.some(sumElement => sumElement.rating === 1)) {
                            const tempSum = tempTally.answer.find(sumElement => sumElement.rating === 1);
                            tempSum.count++;
                            if (typeof tempSum.yesNo === 'string') {
                              tempSum.yesNo = [tempSum.yesNo, answer.answer];
                            } else {
                              tempSum.yesNo.push(answer.answer);
                            }

                          } else {
                            tempTally.answer.push({
                              rating: 1,
                              count: 1,
                              yesNo: answer.answer
                            });

                          }
                        } else {
                          const tempTally = branchDisplayObject.tally.find(tallyElement => tallyElement.id === question.id);
                          if (tempTally.answer.some(sumElement => sumElement.rating === answer.answer)) {
                            const tempSum = tempTally.answer.find(sumElement => sumElement.rating === answer.answer);
                            tempSum.count++;

                          } else {
                            tempTally.answer.push({
                              rating: answer.answer,
                              count: 1
                            });
                          }
                        }
                      }
                    } else {
                      if (branchDisplayObject.tally.length === 0) {
                        branchDisplayObject.tally.push({
                          id: question.id,
                          question: question.Question,
                          answer: [answer.answer],
                          isFreeForm: question.IsFreeForm
                        });
                      } else if (!branchDisplayObject.tally.some(tallyElement => tallyElement.id === question.id)) {
                        branchDisplayObject.tally.push({
                          id: question.id,
                          question: question.Question,
                          answer: [answer.answer],
                          isFreeForm: question.IsFreeForm
                        });
                      } else {
                        const tempTally = branchDisplayObject.tally.find(tallyElement => tallyElement.id === question.id);
                        tempTally.answer.push(answer.answer);
                      }
                    }
                  }
                }
              }
              this.branchDisplayData.push(branchDisplayObject);
              this.ngxLoader.stop();
            },
            surveyError => {
              console.log(surveyError);
              this.ngxLoader.stop();
            });
        },
        errorLAST => {
          console.log(errorLAST);
          this.ngxLoader.stop();
        }
      );
    }
  }

  async getOverview(): Promise<void> {
    const tally = [];
    this.csrSelect = 'ALL';
    this.ngxLoader.start();
    if (this.branchSelect === 'ALL') {
      for (const branch of this.branches) {
        this.dataService.getQuestions().subscribe(
          questionResponse => {
            const theQuestions = questionResponse;
            // if (this.branchSelect !== 'ALL') {
            //   theQuestions = theQuestions.filter(x => {
            //     return this.questionBranchFilter(x);
            //   });
            // }

            this.dataService.getCSRSurveyByBranch
            (branch.id.toString(), moment(this.startDateFilter).utc().format('YYYY-MM-DD'), moment(this.endDateFilter).utc().format('YYYY-MM-DD')).subscribe(
              surveyResonse => {
                this.surveyData = surveyResonse;
                for (const feedback of this.surveyData) {
                  const feedbackArray = JSON.parse(feedback.Feedback);
                  for (const question of theQuestions) {
                    const answer = feedbackArray.find(feedbackObject => feedbackObject.id === question.id);
                    if (answer != null) {
                      if (question.IsFreeForm > 0) {
                        if (tally.length === 0) { // empty
                          if (question.IsFreeForm === 6 && answer.answer !== 2) {
                            tally.push({
                              id: question.id,
                              question: question.Question,
                              answer: [{
                                rating: 1,
                                count: 1,
                                yesNo: [answer.answer]
                              }],
                              isFreeForm: question.IsFreeForm
                            });

                          } else {
                            tally.push({
                              id: question.id,
                              question: question.Question,
                              answer: [{
                                rating: answer.answer,
                                count: 1
                              }],
                              isFreeForm: question.IsFreeForm
                            });
                          }
                        } else if (!tally.some(tallyElement => tallyElement.id === question.id)) { // new question
                          if (question.IsFreeForm === 6 && answer.answer !== 2) {
                            tally.push({
                              id: question.id,
                              question: question.Question,
                              answer: [{
                                rating: 1,
                                count: 1,
                                yesNo: [answer.answer]
                              }],
                              isFreeForm: question.IsFreeForm
                            });
                          } else {
                            tally.push({
                              id: question.id,
                              question: question.Question,
                              answer: [{
                                rating: answer.answer,
                                count: 1
                              }],
                              isFreeForm: question.IsFreeForm
                            });
                          }
                        } else { // existing
                          if (question.IsFreeForm === 6 && answer.answer !== 2) {
                            const tempTally = tally.find(tallyElement => tallyElement.id === question.id);
                            if (tempTally.answer.some(sumElement => sumElement.rating === 1)) {
                              const tempSum = tempTally.answer.find(sumElement => sumElement.rating === 1);
                              tempSum.count++;
                              if (typeof tempSum.yesNo === 'string') {
                                tempSum.yesNo = [tempSum.yesNo, answer.answer];
                              } else {
                                tempSum.yesNo.push(answer.answer);
                              }

                            } else {
                              tempTally.answer.push({
                                rating: 1,
                                count: 1,
                                yesNo: answer.answer
                              });
                            }
                          } else {
                            const tempTally = tally.find(tallyElement => tallyElement.id === question.id);
                            if (tempTally.answer.some(sumElement => sumElement.rating === answer.answer)) {
                              const tempSum = tempTally.answer.find(sumElement => sumElement.rating === answer.answer);
                              tempSum.count++;
                            } else {
                              tempTally.answer.push({
                                rating: answer.answer,
                                count: 1
                              });
                            }
                          }
                        }
                      } else {
                        if (tally.length === 0) {
                          tally.push({
                            id: question.id,
                            question: question.Question,
                            answer: [answer.answer],
                            isFreeForm: question.IsFreeForm
                          });
                        } else if (!tally.some(tallyElement => tallyElement.id === question.id)) {
                          tally.push({
                            id: question.id,
                            question: question.Question,
                            answer: [answer.answer],
                            isFreeForm: question.IsFreeForm
                          });
                        } else {
                          const tempTally = tally.find(tallyElement => tallyElement.id === question.id);
                          tempTally.answer.push(answer.answer);
                        }
                      }
                    }
                  }
                }
                this.overviewDisplayData = tally;
                const type5DisplayData = this.overviewDisplayData.filter(x => x.isFreeForm === 5);
                this.type5BarChartData = [];
                for (const type5 of type5DisplayData) {
                  const tempType5Answer = [];
                  if (type5.answer.some(x => x.rating === -2)) {
                    tempType5Answer.push(
                      {
                        data: [type5.answer.find(x => x.rating === -2).count],
                        backgroundColor: '#DC3545',
                        label: 'Very Dissatisfied',
                        questionID: 1234
                      },
                    );
                  } else {
                    tempType5Answer.push(
                      {data: [0], backgroundColor: '#DC3545', label: 'Very Dissatisfied', questionID: 1234},
                    );
                  }
                  if (type5.answer.some(x => x.rating === -1)) {
                    tempType5Answer.push(
                      {
                        data: [type5.answer.find(x => x.rating === -1).count],
                        backgroundColor: '#FFC107',
                        label: 'Dissatisfied',
                        questionID: 1234
                      },
                    );
                  } else {
                    tempType5Answer.push(
                      {data: [0], backgroundColor: '#FFC107', label: 'Dissatisfied', questionID: 1234},
                    );
                  }
                  if (type5.answer.some(x => x.rating === 0)) {
                    tempType5Answer.push(
                      {
                        data: [type5.answer.find(x => x.rating === 0).count],
                        backgroundColor: '#343A40',
                        label: 'Neutral',
                        questionID: 1234
                      },
                    );
                  } else {
                    tempType5Answer.push(
                      {data: [0], backgroundColor: '#343A40', label: 'Neutral', questionID: 1234},
                    );
                  }
                  if (type5.answer.some(x => x.rating === 1)) {
                    tempType5Answer.push(
                      {
                        data: [type5.answer.find(x => x.rating === 1).count],
                        backgroundColor: '#007BFF',
                        label: 'Satisfied',
                        questionID: 1234
                      },
                    );
                  } else {
                    tempType5Answer.push(
                      {data: [0], backgroundColor: '#007BFF', label: 'Satisfied', questionID: 1234},
                    );
                  }
                  if (type5.answer.some(x => x.rating === 2)) {
                    tempType5Answer.push(
                      {
                        data: [type5.answer.find(x => x.rating === 2).count],
                        backgroundColor: '#28A745',
                        label: 'Very Satisfied',
                        questionID: 1234
                      },
                    );
                  } else {
                    tempType5Answer.push(
                      {data: [0], backgroundColor: '#28A745', label: 'Very Satisfied', questionID: 1234},
                    );
                  }
                  this.type5BarChartData.push({question: [type5.question], answer: tempType5Answer});
                }

                // TYPE 2 QUESTIONS HERE
                const type2DisplayData = this.overviewDisplayData.filter(x => x.isFreeForm === 2);
                this.type2BarChartData = [];
                for (const type2 of type2DisplayData) {
                  const tempType2Answer = [];
                  tempType2Answer.push(
                    {data: [type2.answer.find(x => x.rating === 1).count], label: 'No'},
                  );
                  tempType2Answer.push(
                    {data: [type2.answer.find(x => x.rating === 2).count], label: 'Yes'},
                  );
                  this.type2BarChartData.push({question: [type2.question], answer: tempType2Answer});
                }
                // TYPE 6 QUESTIONS HERE
                const type6DisplayData = this.overviewDisplayData.filter(x => x.isFreeForm === 6);
                this.type6BarChartData = [];
                for (const type6 of type6DisplayData) {
                  const tempType6Answer = [];
                  tempType6Answer.push(
                    {data: [type6.answer.find(x => x.rating === 1).count], label: 'No'},
                  );
                  tempType6Answer.push(
                    {data: [type6.answer.find(x => x.rating === 2).count], label: 'Yes'},
                  );
                  this.type6BarChartData.push({question: [type6.question], answer: tempType6Answer});
                }
                this.ngxLoader.stop();
              },
              surveyError => {
                console.log(surveyError);
                this.ngxLoader.stop();
              });

          },
          errorLAST => {
            console.log(errorLAST);
            this.ngxLoader.stop();
          }
        );
      }
    } else {
      this.dataService.getQuestions().subscribe(
        questionResponse => {
          let theQuestions = questionResponse;
          if (this.branchSelect !== 'ALL') {
            theQuestions = theQuestions.filter(x => {
              return this.questionBranchFilter(x);
            });
          }
          this.dataService.getCSRSurveyByBranch
          (this.branchSelect.toString(), moment(this.startDateFilter).utc().format('YYYY-MM-DD'), moment(this.endDateFilter).utc().format('YYYY-MM-DD')).subscribe(
            surveyResonse => {
              this.surveyData = surveyResonse;
              for (const feedback of this.surveyData) {
                const feedbackArray = JSON.parse(feedback.Feedback);
                for (const question of theQuestions) {
                  const answer = feedbackArray.find(feedbackObject => feedbackObject.id === question.id);
                  if (answer != null) {
                    if (question.IsFreeForm > 0) {
                      if (tally.length === 0) { // empty
                        if (question.IsFreeForm === 6 && answer.answer !== 2) {
                          tally.push({
                            id: question.id,
                            question: question.Question,
                            answer: [{
                              rating: 1,
                              count: 1,
                              yesNo: [answer.answer]
                            }],
                            isFreeForm: question.IsFreeForm
                          });

                        } else {
                          tally.push({
                            id: question.id,
                            question: question.Question,
                            answer: [{
                              rating: answer.answer,
                              count: 1
                            }],
                            isFreeForm: question.IsFreeForm
                          });
                        }
                      } else if (!tally.some(tallyElement => tallyElement.id === question.id)) { // new question
                        if (question.IsFreeForm === 6 && answer.answer !== 2) {
                          tally.push({
                            id: question.id,
                            question: question.Question,
                            answer: [{
                              rating: 1,
                              count: 1,
                              yesNo: [answer.answer]
                            }],
                            isFreeForm: question.IsFreeForm
                          });
                        } else {
                          tally.push({
                            id: question.id,
                            question: question.Question,
                            answer: [{
                              rating: answer.answer,
                              count: 1
                            }],
                            isFreeForm: question.IsFreeForm
                          });
                        }
                      } else { // existing
                        if (question.IsFreeForm === 6 && answer.answer !== 2) {
                          const tempTally = tally.find(tallyElement => tallyElement.id === question.id);
                          if (tempTally.answer.some(sumElement => sumElement.rating === 1)) {
                            const tempSum = tempTally.answer.find(sumElement => sumElement.rating === 1);
                            tempSum.count++;
                            if (typeof tempSum.yesNo === 'string') {
                              tempSum.yesNo = [tempSum.yesNo, answer.answer];
                            } else {
                              tempSum.yesNo.push(answer.answer);
                            }

                          } else {
                            tempTally.answer.push({
                              rating: 1,
                              count: 1,
                              yesNo: answer.answer
                            });

                          }
                        } else {
                          const tempTally = tally.find(tallyElement => tallyElement.id === question.id);
                          if (tempTally.answer.some(sumElement => sumElement.rating === answer.answer)) {
                            const tempSum = tempTally.answer.find(sumElement => sumElement.rating === answer.answer);
                            tempSum.count++;

                          } else {
                            tempTally.answer.push({
                              rating: answer.answer,
                              count: 1
                            });
                          }
                        }
                      }
                    } else {
                      if (tally.length === 0) {
                        tally.push({
                          id: question.id,
                          question: question.Question,
                          answer: [answer.answer],
                          isFreeForm: question.IsFreeForm
                        });
                      } else if (!tally.some(tallyElement => tallyElement.id === question.id)) {
                        tally.push({
                          id: question.id,
                          question: question.Question,
                          answer: [answer.answer],
                          isFreeForm: question.IsFreeForm
                        });
                      } else {
                        const tempTally = tally.find(tallyElement => tallyElement.id === question.id);
                        tempTally.answer.push(answer.answer);
                      }
                    }
                  }
                }
              }
              this.overviewDisplayData = tally;
              const type5DisplayData = this.overviewDisplayData.filter(x => x.isFreeForm === 5);
              this.type5BarChartData = [];
              for (const type5 of type5DisplayData) {
                const tempType5Answer = [];
                if (type5.answer.some(x => x.rating === -2)) {
                  tempType5Answer.push(
                    {
                      data: [type5.answer.find(x => x.rating === -2).count],
                      backgroundColor: '#DC3545',
                      label: 'Very Dissatisfied',
                      questionID: 1234
                    },
                  );
                } else {
                  tempType5Answer.push(
                    {data: [0], backgroundColor: '#DC3545', label: 'Very Dissatisfied', questionID: 1234},
                  );
                }
                if (type5.answer.some(x => x.rating === -1)) {
                  tempType5Answer.push(
                    {
                      data: [type5.answer.find(x => x.rating === -1).count],
                      backgroundColor: '#FFC107',
                      label: 'Dissatisfied',
                      questionID: 1234
                    },
                  );
                } else {
                  tempType5Answer.push(
                    {data: [0], backgroundColor: '#FFC107', label: 'Dissatisfied', questionID: 1234},
                  );
                }
                if (type5.answer.some(x => x.rating === 0)) {
                  tempType5Answer.push(
                    {
                      data: [type5.answer.find(x => x.rating === 0).count],
                      backgroundColor: '#343A40',
                      label: 'Neutral',
                      questionID: 1234
                    },
                  );
                } else {
                  tempType5Answer.push(
                    {data: [0], backgroundColor: '#343A40', label: 'Neutral', questionID: 1234},
                  );
                }
                if (type5.answer.some(x => x.rating === 1)) {
                  tempType5Answer.push(
                    {
                      data: [type5.answer.find(x => x.rating === 1).count],
                      backgroundColor: '#007BFF',
                      label: 'Satisfied',
                      questionID: 1234
                    },
                  );
                } else {
                  tempType5Answer.push(
                    {data: [0], backgroundColor: '#007BFF', label: 'Satisfied', questionID: 1234},
                  );
                }
                if (type5.answer.some(x => x.rating === 2)) {
                  tempType5Answer.push(
                    {
                      data: [type5.answer.find(x => x.rating === 2).count],
                      backgroundColor: '#28A745',
                      label: 'Very Satisfied',
                      questionID: 1234
                    },
                  );
                } else {
                  tempType5Answer.push(
                    {data: [0], backgroundColor: '#28A745', label: 'Very Satisfied', questionID: 1234},
                  );
                }
                this.type5BarChartData.push({question: [type5.question], answer: tempType5Answer});
              }
              const type2DisplayData = this.overviewDisplayData.filter(x => x.isFreeForm === 2);
              this.type2BarChartData = [];
              for (const type2 of type2DisplayData) {
                const tempType2Answer = [];
                tempType2Answer.push(
                  {data: [type2.answer.find(x => x.rating === 1).count], label: 'No'},
                );
                tempType2Answer.push(
                  {data: [type2.answer.find(x => x.rating === 2).count], label: 'Yes'},
                );
                this.type2BarChartData.push({question: [type2.question], answer: tempType2Answer});
              }

              // TYPE 6 QUESTIONS HERE
              const type6DisplayData = this.overviewDisplayData.filter(x => x.isFreeForm === 6);
              this.type6BarChartData = [];
              for (const type6 of type6DisplayData) {
                const tempType6Answer = [];
                tempType6Answer.push(
                  {data: [type6.answer.find(x => x.rating === 1).count], label: 'No'},
                );
                tempType6Answer.push(
                  {data: [type6.answer.find(x => x.rating === 2).count], label: 'Yes'},
                );
                this.type6BarChartData.push({question: [type6.question], answer: tempType6Answer});
              }

              this.ngxLoader.stop();
            },
            surveyError => {
              console.log(surveyError);
              this.ngxLoader.stop();
            });

        },
        errorLAST => {
          console.log(errorLAST);
          this.ngxLoader.stop();
        }
      );
    }


  }


  selectBranch(branchSelected): void {
    this.dataService.getQuestions().subscribe(
      questionResponse => {
        this.questions = questionResponse;
        if (this.branchSelect !== 'ALL') {
          this.questions = this.questions.filter(x => {
            return this.questionBranchFilter(x);
          });
        }
      });
    if (this.tableSelect !== 'branchTally') {
      this.csrs = [];
      this.isCsvButtonDisabled = true;
      this.dataService.getAllFrontlinersByBranch(56, branchSelected).subscribe(
        response => {
          const csrResponses = response;
          for (const csrResponse of csrResponses) {
            if (csrResponse.STATUS === 'active') {
              this.csrs.push({FRONTLINER_ID: csrResponse.FRONTLINER_ID, FULLNAME: csrResponse.FULLNAME});
            }
          }
          this.csrSelect = 'ALL';
          this.selectCsr(this.csrSelect);

        },
        err => console.log(err)
      );
    } else if (this.tableSelect === 'branchTally') {
      this.getBranchTally();
    }
  }

  getRating(ratingNumber: number, answerArray: any[]): number {
    if (answerArray.some(element => element.rating === ratingNumber)) {
      return answerArray.find(element => element.rating === ratingNumber).count;
    } else {
      return 0;
    }
  }

  getYesNo(answerArray: any[]): string {
    if (answerArray.some(element => element.rating === 1)) {
      return answerArray.find(element => element.rating === 1).yesNo;
    } else {
      return '';
    }
  }


  updateStartDate(event): void {
    this.startDateFilter = new Date(event);
    if (this.tableSelect === 'tally' || this.tableSelect === 'raw') {
      this.selectCsr(this.csrSelect);
    } else if (this.tableSelect === 'overview') {
      this.getOverview();
    } else if (this.tableSelect === 'branchTally') {
      this.getBranchTally();
    }
  }

  updateEndDate(event): void {
    this.endDateFilter = new Date(event);

    // this.endDateFilter.setDate(this.endDateFilter.getDate() + 1);
    console.log(event);
    if (this.tableSelect === 'tally' || this.tableSelect === 'raw') {
      this.selectCsr(this.csrSelect);
    } else if (this.tableSelect === 'overview') {
      this.getOverview();
    } else if (this.tableSelect === 'branchTally') {
      this.getBranchTally();
    }
  }

  downloadCSV(): void {
    this.ngxLoader.start();
    let branchName = '';
    if (this.branches.some(x => x.id === this.branchSelect)) {
      branchName = this.branches.find(x => x.id === this.branchSelect).BRANCH_NAME;
    } else {
      if (this.tableSelect === 'regionTally') {
        branchName = 'Region Tally';
      } else {
        branchName = 'Branch Tally';
      }
    }
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(branchName);
    const header: string[] = [];

    if (this.tableSelect !== 'overview') {
      if (this.tableSelect === 'regionTally') {
        header.push('REGION NAME');
      }
      else {
        header.push('CSR NAME');
        header.push('Service Order');
      }
      if (this.tableSelect === 'raw') {
        header.push('Customer Number');
      }
      for (const question of this.questions) {
        if (question.IsFreeForm === 5 && this.tableSelect !== 'raw') {
          header.push(question.Question);
          header.push('');
          header.push('');
          header.push('');
          header.push('');
        } else {
          header.push(question.Question);
        }
      }
      let headerLetter = 'A';
      for (const question of this.questions) {
        if (question.IsFreeForm === 5 && this.tableSelect !== 'raw') {
          for (let j = 0; j < 5; j++) {
            headerLetter = String.fromCharCode(headerLetter.charCodeAt(0) + 1);
          }
        } else {
          headerLetter = String.fromCharCode(headerLetter.charCodeAt(0) + 1);
        }
      }
      worksheet.mergeCells('A1:' + headerLetter + '2');
      worksheet.getCell('A1').value = branchName + ' - CSR DATA';
      worksheet.getCell('A1').alignment = {vertical: 'middle', horizontal: 'center'};
      worksheet.getCell('A1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: '000000'}
      };
      worksheet.getCell('A1').font = {
        name: 'Samsung Sharp Sans',
        color: {argb: 'FFFFFF'},
        family: 2,
        size: 18
      };

      worksheet.addRow(header);
    }


    if (this.tableSelect !== 'raw') {
      const type5Questions = this.questions.filter(x => x.IsFreeForm === 5);
      for (const question of type5Questions) {
        for (let i = 1; i < 20; i++) {
          const tempCell = worksheet.getRow(3).getCell(i);
          if (tempCell.value === question.Question) {
            const firstCellMergeAddress = tempCell.address;
            const firstCellMergeAddressLetter = firstCellMergeAddress[0];
            let lastCellMergeAddressLetter = firstCellMergeAddressLetter;
            for (let letterIndex = 0; letterIndex < 4; letterIndex++) {
              lastCellMergeAddressLetter = String.fromCharCode(lastCellMergeAddressLetter.charCodeAt(0) + 1);
            }
            worksheet.mergeCells(firstCellMergeAddress + ':' + lastCellMergeAddressLetter + '3');
            break;
          }
        }
      }
    }
    const mainBody: [any[]] = [[]];
    let questionCount = this.questions.length;
    worksheet.columns.forEach(column => {
      column.style.font = {name: 'Samsung Sharp Sans'};
    });
    if (this.tableSelect === 'tally') {
      for (const csrData of this.csrDisplayData) {
        const tempBodyArray: any[] = [];
        tempBodyArray.push(csrData.CSRName);
        if (csrData.tally.length === 0) {
          questionCount = this.questions.length; // reset questionCount
          for (const question of this.questions) {
            tempBodyArray.push('NO DATA');
          }
        } else {
          const tallyCount = csrData.tally.length;
          for (const tally of csrData.tally) {
            if (tally.isFreeForm === 5) {
              let temp5Cell: any = {richText: []};
              if (tally.answer.some(x => x.rating === -2)) {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '971500'}, name: 'Samsung Sharp Sans', family: 2},
                    text: 'Very Dissatisfied:' + tally.answer.find(x => x.rating === -2).count
                  }
                );
                tempBodyArray.push(temp5Cell);
              } else {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '971500'}, name: 'Samsung Sharp Sans', family: 2},
                    text: 'Very Dissatisfied:0'
                  }
                );
                tempBodyArray.push(temp5Cell);
              }
              if (tally.answer.some(x => x.rating === -1)) {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: 'FF8C00'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nDissatisfied:' + tally.answer.find(x => x.rating === -1).count
                  }
                );
                tempBodyArray.push(temp5Cell);
              } else {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: 'FF8C00'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nDissatisfied:0'
                  }
                );
                tempBodyArray.push(temp5Cell);
              }
              if (tally.answer.some(x => x.rating === 0)) {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '000000'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nNeutral:' + tally.answer.find(x => x.rating === 0).count
                  }
                );
                tempBodyArray.push(temp5Cell);
              } else {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '000000'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nNeutral:0'
                  }
                );
                tempBodyArray.push(temp5Cell);
              }
              if (tally.answer.some(x => x.rating === 1)) {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '00008B'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nSatisfied:' + tally.answer.find(x => x.rating === 1).count
                  }
                );
                tempBodyArray.push(temp5Cell);
              } else {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '00008B'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nSatisfied:0'
                  }
                );
                tempBodyArray.push(temp5Cell);
              }
              if (tally.answer.some(x => x.rating === 2)) {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '056608'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nVery Satisfied:' + tally.answer.find(x => x.rating === 2).count
                  }
                );
                tempBodyArray.push(temp5Cell);
              } else {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '056608'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nVery Satisfied:0'
                  }
                );
                tempBodyArray.push(temp5Cell);
              }
            } else if (tally.isFreeForm === 6) {
              let yesNoCell = '';
              if (tally.answer.some(x => x.rating === 2)) {
                yesNoCell += 'Yes Count: ' + tally.answer.find(x => x.rating === 2).count + ' \r\n';
              } else {
                yesNoCell += 'Yes Count: 0' + '\r\n';
              }
              if (tally.answer.some(x => x.rating === 1)) {
                yesNoCell += 'No Count: ' + tally.answer.find(x => x.rating === 1).count + ' \r\n';
                yesNoCell += 'Reasons: \r\n';
                for (const answer of tally.answer.find(x => x.rating === 1).yesNo) {
                  yesNoCell += '"';
                  yesNoCell += answer;
                  yesNoCell += '"' + '\r\n';
                }
              } else {
                yesNoCell += 'No Count: 0 \r\n';
              }
              tempBodyArray.push(yesNoCell);
            } else if (tally.isFreeForm === 2) {
              let yesNoCell = '';
              if (tally.answer.some(x => x.rating === 2)) {
                yesNoCell += 'Yes Count: ' + tally.answer.find(x => x.rating === 2).count + ' \r\n';
              } else {
                yesNoCell += 'Yes Count: 0' + '\r\n';
              }
              if (tally.answer.some(x => x.rating === 1)) {
                yesNoCell += 'No Count: ' + tally.answer.find(x => x.rating === 1).count + ' \r\n';
              } else {
                yesNoCell += 'No Count: 0 \r\n';
              }
              tempBodyArray.push(yesNoCell);
            } else {
              let tempTextCell = '';
              for (const answer of tally.answer) {
                tempTextCell += '"';
                tempTextCell += answer;
                tempTextCell += '"' + '\r\n';
              }
              tempBodyArray.push(tempTextCell);
            }
          }
        }
        mainBody.push(tempBodyArray);
      }
    } else if (this.tableSelect === 'branchTally') {
      for (const branchName of this.branchDisplayData) {
        const tempBodyArray: any[] = [];
        tempBodyArray.push(branchName.BranchName);
        if (branchName.tally.length === 0) {
          questionCount = this.questions.length; // reset questionCount
          for (const question of this.questions) {
            tempBodyArray.push('NO DATA');
          }
        } else {
          const tallyCount = branchName.tally.length;
          for (const tally of branchName.tally) {
            if (tally.isFreeForm === 5) {
              let temp5Cell: any = {richText: []};
              if (tally.answer.some(x => x.rating === -2)) {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '971500'}, name: 'Samsung Sharp Sans', family: 2},
                    text: 'Very Dissatisfied:' + tally.answer.find(x => x.rating === -2).count
                  }
                );
                tempBodyArray.push(temp5Cell);
              } else {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '971500'}, name: 'Samsung Sharp Sans', family: 2},
                    text: 'Very Dissatisfied:0'
                  }
                );
                tempBodyArray.push(temp5Cell);
              }
              if (tally.answer.some(x => x.rating === -1)) {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: 'FF8C00'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nDissatisfied:' + tally.answer.find(x => x.rating === -1).count
                  }
                );
                tempBodyArray.push(temp5Cell);
              } else {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: 'FF8C00'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nDissatisfied:0'
                  }
                );
                tempBodyArray.push(temp5Cell);
              }
              if (tally.answer.some(x => x.rating === 0)) {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '000000'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nNeutral:' + tally.answer.find(x => x.rating === 0).count
                  }
                );
                tempBodyArray.push(temp5Cell);
              } else {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '000000'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nNeutral:0'
                  }
                );
                tempBodyArray.push(temp5Cell);
              }
              if (tally.answer.some(x => x.rating === 1)) {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '00008B'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nSatisfied:' + tally.answer.find(x => x.rating === 1).count
                  }
                );
                tempBodyArray.push(temp5Cell);
              } else {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '00008B'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nSatisfied:0'
                  }
                );
                tempBodyArray.push(temp5Cell);
              }
              if (tally.answer.some(x => x.rating === 2)) {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '056608'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nVery Satisfied:' + tally.answer.find(x => x.rating === 2).count
                  }
                );
                tempBodyArray.push(temp5Cell);
              } else {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '056608'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nVery Satisfied:0'
                  }
                );
                tempBodyArray.push(temp5Cell);
              }
            } else if (tally.isFreeForm === 6) {
              let yesNoCell = '';
              if (tally.answer.some(x => x.rating === 2)) {
                yesNoCell += 'Yes Count: ' + tally.answer.find(x => x.rating === 2).count + ' \r\n';
              } else {
                yesNoCell += 'Yes Count: 0' + '\r\n';
              }
              if (tally.answer.some(x => x.rating === 1)) {
                yesNoCell += 'No Count: ' + tally.answer.find(x => x.rating === 1).count + ' \r\n';
                yesNoCell += 'Reasons: \r\n';
                for (const answer of tally.answer.find(x => x.rating === 1).yesNo) {
                  yesNoCell += '"';
                  yesNoCell += answer;
                  yesNoCell += '"' + '\r\n';
                }
              } else {
                yesNoCell += 'No Count: 0 \r\n';
              }
              tempBodyArray.push(yesNoCell);
            } else if (tally.isFreeForm === 2) {
              let yesNoCell = '';
              if (tally.answer.some(x => x.rating === 2)) {
                yesNoCell += 'Yes Count: ' + tally.answer.find(x => x.rating === 2).count + ' \r\n';
              } else {
                yesNoCell += 'Yes Count: 0' + '\r\n';
              }
              if (tally.answer.some(x => x.rating === 1)) {
                yesNoCell += 'No Count: ' + tally.answer.find(x => x.rating === 1).count + ' \r\n';
              } else {
                yesNoCell += 'No Count: 0 \r\n';
              }
              tempBodyArray.push(yesNoCell);
            } else {
              let tempTextCell = '';
              for (const answer of tally.answer) {
                tempTextCell += '"';
                tempTextCell += answer;
                tempTextCell += '"' + '\r\n';
              }
              tempBodyArray.push(tempTextCell);
            }
          }
        }
        mainBody.push(tempBodyArray);
      }
    } else if (this.tableSelect === 'raw') {
      // for (const csrData of this.csrDisplayData) {
      for (let i = 0; i < this.csrDisplayData.length; i++) {
        const csrData = this.csrDisplayData[i];
        let tempBodyArray: any[] = [];
        console.log(csrData);
        // tempBodyArray.push(csrData.MobileNumber);
        tempBodyArray.push(csrData.CSRName);
        tempBodyArray.push(csrData.ServiceOrder);
        tempBodyArray.push(csrData.MobileNumber);
        if (csrData.raw.length === 0) {
          questionCount = this.questions.length; // reset questionCount
          for (const question of this.questions) {
            tempBodyArray.push('NO DATA');
          }
        } else {
          const tallyCount = csrData.raw.length;
          for (let j = 0; j < csrData.raw.length; j++) {
            // for (const raw of csrData.raw) {
            const raw = csrData.raw[j];
            tempBodyArray = [];
            tempBodyArray.push(raw.CSRName);
            tempBodyArray.push(raw.ServiceOrder);
            tempBodyArray.push(raw.MobileNumber);
            for (const question of this.questions) {
              if (question.IsFreeForm === 5) {
                let temp5Cell: any = {richText: []};
                if (this.getQuestionFeedbackAnswer(question.id, raw.Feedback).answer === -2) {
                  temp5Cell = {richText: []};
                  temp5Cell.richText.push({
                      font: {size: 12, color: {argb: '971500'}, name: 'Samsung Sharp Sans', family: 2},
                      text: 'Very Dissatisfied'
                    }
                  );
                }
                if (this.getQuestionFeedbackAnswer(question.id, raw.Feedback).answer === -1) {
                  temp5Cell = {richText: []};
                  temp5Cell.richText.push({
                      font: {size: 12, color: {argb: 'FF8C00'}, name: 'Samsung Sharp Sans', family: 2},
                      text: 'Dissatisfied'
                    }
                  );
                }
                if (this.getQuestionFeedbackAnswer(question.id, raw.Feedback).answer === 0) {
                  temp5Cell = {richText: []};
                  temp5Cell.richText.push({
                      font: {size: 12, color: {argb: '000000'}, name: 'Samsung Sharp Sans', family: 2},
                      text: 'Neutral'
                    }
                  );
                }
                if (this.getQuestionFeedbackAnswer(question.id, raw.Feedback).answer === 1) {
                  temp5Cell = {richText: []};
                  temp5Cell.richText.push({
                      font: {size: 12, color: {argb: '00008B'}, name: 'Samsung Sharp Sans', family: 2},
                      text: 'Satisfied'
                    }
                  );
                }
                if (this.getQuestionFeedbackAnswer(question.id, raw.Feedback).answer === 2) {
                  temp5Cell = {richText: []};
                  temp5Cell.richText.push({
                      font: {size: 12, color: {argb: '056608'}, name: 'Samsung Sharp Sans', family: 2},
                      text: 'Very Satisfied:'
                    }
                  );
                }
                tempBodyArray.push(temp5Cell);
              } else if (question.IsFreeForm === 6) {
                let yesNoCell = '';
                if (this.getQuestionFeedbackAnswer(question.id, raw.Feedback).answer !== '') {
                  if (this.getQuestionFeedbackAnswer(question.id, raw.Feedback).answer === 2) {
                    yesNoCell += 'Yes';
                  } else {
                    const tempAnswer = this.getQuestionFeedbackAnswer(question.id, raw.Feedback).answer;
                    if (tempAnswer == null) {
                      yesNoCell += 'No, because: (NO REASON STATED)';
                    } else {
                      yesNoCell += 'No, because: ' + tempAnswer === undefined ? '' : tempAnswer + '';
                    }
                  }
                } else {
                  yesNoCell += 'NO DATA';
                }
                tempBodyArray.push(yesNoCell);
              } else if (question.IsFreeForm === 2) {
                let yesNoCell = '';
                if (this.getQuestionFeedbackAnswer(question.id, raw.Feedback).answer === 2) {
                  yesNoCell += 'Yes';
                } else if (this.getQuestionFeedbackAnswer(question.id, raw.Feedback).answer === 1) {
                  yesNoCell += 'No';
                } else {
                  yesNoCell += 'NO DATA';
                }
                tempBodyArray.push(yesNoCell);
              } else {
                tempBodyArray.push(this.getQuestionFeedbackAnswer(question.id, raw.Feedback).answer);
              }
            }
            mainBody.push(tempBodyArray);
          }
        }
      }
    } else if (this.tableSelect === 'overview') {

      let headerLetter = 'A';
      for (const question of this.questions) {
        if (question.IsFreeForm === 5) {
          for (let j = 0; j < 5; j++) {
            headerLetter = String.fromCharCode(headerLetter.charCodeAt(0) + 1);
          }
        } else {
          headerLetter = String.fromCharCode(headerLetter.charCodeAt(0) + 1);
        }
      }
      worksheet.mergeCells('A1:' + headerLetter + '2');
      if (this.branchSelect === 'ALL') {
        worksheet.getCell('A1').value = this.branchSelect + ' - OVERVIEW';
      } else {
        worksheet.getCell('A1').value = this.branches.find(x => x.id === this.branchSelect).BRANCH_NAME + ' - OVERVIEW';
      }
      worksheet.getCell('A1').alignment = {vertical: 'middle', horizontal: 'center'};
      worksheet.getCell('A1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: '000000'}
      };
      worksheet.getCell('A1').font = {
        name: 'Samsung Sharp Sans',
        color: {argb: 'FFFFFF'},
        family: 2,
        size: 18
      };
      let excelRow: any[] = [];
      let excelCell = {richText: []};
      for (const type5Data of this.type5BarChartData) {
        excelRow = [];
        excelCell = {richText: []};
        excelCell.richText.push(
          {
            font: {size: 12, color: {argb: '000000'}, name: 'Samsung Sharp Sans', family: 2},
            text: type5Data.question[0]
          }
        );
        excelRow.push(excelCell);
        for (const ans of type5Data.answer) {
          excelCell = {richText: []};
          if (ans.label === 'Very Dissatisfied') {
            excelCell.richText.push(
              {
                font: {size: 12, color: {argb: '971500'}, name: 'Samsung Sharp Sans', family: 2},
                text: ans.label + ': ' + ans.data[0]
              }
            );
          } else if (ans.label === 'Dissatisfied') {
            excelCell.richText.push(
              {
                font: {size: 12, color: {argb: 'FF8C00'}, name: 'Samsung Sharp Sans', family: 2},
                text: ans.label + ': ' + ans.data[0]
              }
            );
          } else if (ans.label === 'Neutral') {
            excelCell.richText.push(
              {
                font: {size: 12, color: {argb: '000000'}, name: 'Samsung Sharp Sans', family: 2},
                text: ans.label + ': ' + ans.data[0]
              }
            );
          } else if (ans.label === 'Satisfied') {
            excelCell.richText.push(
              {
                font: {size: 12, color: {argb: '00008B'}, name: 'Samsung Sharp Sans', family: 2},
                text: ans.label + ': ' + ans.data[0]
              }
            );
          } else if (ans.label === 'Very Satisfied') {
            excelCell.richText.push(
              {
                font: {size: 12, color: {argb: '056608'}, name: 'Samsung Sharp Sans', family: 2},
                text: ans.label + ': ' + ans.data[0]
              }
            );
          }
          excelRow.push(excelCell);
        }
        mainBody.push(excelRow);
      }
      excelRow = [];
      for (const type2Data of this.type2BarChartData) {
        excelCell = {richText: []};
        excelCell.richText.push(
          {
            font: {size: 12, color: {argb: '000000'}, name: 'Samsung Sharp Sans', family: 2},
            text: type2Data.question[0]
          }
        );
        excelRow.push(excelCell);
        for (const ans of type2Data.answer) {
          excelCell = {richText: []};
          if (ans.label === 'No') {
            excelCell.richText.push(
              {
                font: {size: 12, color: {argb: '971500'}, name: 'Samsung Sharp Sans', family: 2},
                text: ans.label + ': ' + ans.data[0]
              }
            );
          } else if (ans.label === 'Yes') {
            excelCell.richText.push(
              {
                font: {size: 12, color: {argb: '056608'}, name: 'Samsung Sharp Sans', family: 2},
                text: ans.label + ': ' + ans.data[0]
              }
            );
          }
          excelRow.push(excelCell);
        }
        mainBody.push(excelRow);
      }
      excelRow = [];
      for (const type6Data of this.type6BarChartData) {
        excelCell = {richText: []};
        excelCell.richText.push(
          {
            font: {size: 12, color: {argb: '000000'}, name: 'Samsung Sharp Sans', family: 2},
            text: type6Data.question[0]
          }
        );
        excelRow.push(excelCell);
        for (const ans of type6Data.answer) {
          excelCell = {richText: []};
          if (ans.label === 'No') {
            excelCell.richText.push(
              {
                font: {size: 12, color: {argb: '971500'}, name: 'Samsung Sharp Sans', family: 2},
                text: ans.label + ': ' + ans.data[0]
              }
            );
          } else if (ans.label === 'Yes') {
            excelCell.richText.push(
              {
                font: {size: 12, color: {argb: '056608'}, name: 'Samsung Sharp Sans', family: 2},
                text: ans.label + ': ' + ans.data[0]
              }
            );
          }
          excelRow.push(excelCell);
        }
        mainBody.push(excelRow);
      }

      console.log(this.type5BarChartData);
      console.log(this.type6BarChartData);
    } else if (this.tableSelect === 'regionTally') {
      for (const branchName of this.regionDisplayData) {
        const tempBodyArray: any[] = [];
        tempBodyArray.push(branchName.RegionName);
        if (branchName.tally.length === 0) {
          questionCount = this.questions.length; // reset questionCount
          for (const question of this.questions) {
            tempBodyArray.push('NO DATA');
          }
        } else {
          const tallyCount = branchName.tally.length;
          for (const tally of branchName.tally) {
            if (tally.isFreeForm === 5) {
              let temp5Cell: any = {richText: []};
              if (tally.answer.some(x => x.rating === -2)) {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '971500'}, name: 'Samsung Sharp Sans', family: 2},
                    text: 'Very Dissatisfied:' + tally.answer.find(x => x.rating === -2).count
                  }
                );
                tempBodyArray.push(temp5Cell);
              } else {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '971500'}, name: 'Samsung Sharp Sans', family: 2},
                    text: 'Very Dissatisfied:0'
                  }
                );
                tempBodyArray.push(temp5Cell);
              }
              if (tally.answer.some(x => x.rating === -1)) {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: 'FF8C00'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nDissatisfied:' + tally.answer.find(x => x.rating === -1).count
                  }
                );
                tempBodyArray.push(temp5Cell);
              } else {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: 'FF8C00'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nDissatisfied:0'
                  }
                );
                tempBodyArray.push(temp5Cell);
              }
              if (tally.answer.some(x => x.rating === 0)) {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '000000'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nNeutral:' + tally.answer.find(x => x.rating === 0).count
                  }
                );
                tempBodyArray.push(temp5Cell);
              } else {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '000000'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nNeutral:0'
                  }
                );
                tempBodyArray.push(temp5Cell);
              }
              if (tally.answer.some(x => x.rating === 1)) {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '00008B'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nSatisfied:' + tally.answer.find(x => x.rating === 1).count
                  }
                );
                tempBodyArray.push(temp5Cell);
              } else {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '00008B'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nSatisfied:0'
                  }
                );
                tempBodyArray.push(temp5Cell);
              }
              if (tally.answer.some(x => x.rating === 2)) {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '056608'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nVery Satisfied:' + tally.answer.find(x => x.rating === 2).count
                  }
                );
                tempBodyArray.push(temp5Cell);
              } else {
                temp5Cell = {richText: []};
                temp5Cell.richText.push({
                    font: {size: 12, color: {argb: '056608'}, name: 'Samsung Sharp Sans', family: 2},
                    text: ' \nVery Satisfied:0'
                  }
                );
                tempBodyArray.push(temp5Cell);
              }
            } else if (tally.isFreeForm === 6) {
              let yesNoCell = '';
              if (tally.answer.some(x => x.rating === 2)) {
                yesNoCell += 'Yes Count: ' + tally.answer.find(x => x.rating === 2).count + ' \r\n';
              } else {
                yesNoCell += 'Yes Count: 0' + '\r\n';
              }
              if (tally.answer.some(x => x.rating === 1)) {
                yesNoCell += 'No Count: ' + tally.answer.find(x => x.rating === 1).count + ' \r\n';
                yesNoCell += 'Reasons: \r\n';
                for (const answer of tally.answer.find(x => x.rating === 1).yesNo) {
                  yesNoCell += '"';
                  yesNoCell += answer;
                  yesNoCell += '"' + '\r\n';
                }
              } else {
                yesNoCell += 'No Count: 0 \r\n';
              }
              tempBodyArray.push(yesNoCell);
            } else if (tally.isFreeForm === 2) {
              let yesNoCell = '';
              if (tally.answer.some(x => x.rating === 2)) {
                yesNoCell += 'Yes Count: ' + tally.answer.find(x => x.rating === 2).count + ' \r\n';
              } else {
                yesNoCell += 'Yes Count: 0' + '\r\n';
              }
              if (tally.answer.some(x => x.rating === 1)) {
                yesNoCell += 'No Count: ' + tally.answer.find(x => x.rating === 1).count + ' \r\n';
              } else {
                yesNoCell += 'No Count: 0 \r\n';
              }
              tempBodyArray.push(yesNoCell);
            } else {
              let tempTextCell = '';
              for (const answer of tally.answer) {
                tempTextCell += '"';
                tempTextCell += answer;
                tempTextCell += '"' + '\r\n';
              }
              tempBodyArray.push(tempTextCell);
            }
          }
        }
        mainBody.push(tempBodyArray);
      }
    }

    for (const row of mainBody) {
      worksheet.addRow(row);
    }

    worksheet.columns.forEach((column, i) => {
      let maxLength = 0;
      column['eachCell']({includeEmpty: true}, (cell) => {
        let columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = 40;

      // column.style.font = { name: 'Samsung Sharp Sans' };
    });

    worksheet.getColumn(6).alignment = {vertical: 'middle', wrapText: true};
    worksheet.getColumn(5).alignment = {vertical: 'middle', wrapText: true};
    worksheet.getColumn(4).alignment = {vertical: 'middle', wrapText: true};
    worksheet.getColumn(3).alignment = {vertical: 'middle', wrapText: true};
    worksheet.getColumn(2).alignment = {vertical: 'middle', wrapText: true};

    let letter = 'A';

    for (let i = 0; i < this.questions.length; i++) {
      if (this.questions[i].IsFreeForm) {
        for (let j = 1; j <= 5; j++) {
          worksheet.getCell(letter + '3').font = {
            name: 'Samsung Sharp Sans',
            color: {argb: '000000'},
            family: 2,
            size: 14,
            bold: true
          };
          letter = String.fromCharCode(letter.charCodeAt(0) + 1);
        }
      } else {
        worksheet.getCell(letter + '3').font = {
          name: 'Samsung Sharp Sans',
          color: {argb: '000000'},
          family: 2,
          size: 14,
          bold: true
        };
        letter = String.fromCharCode(letter.charCodeAt(0) + 1);
      }
    }


    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      fs.saveAs(blob, branchName + ' - CSR Data.xlsx');
    });
    this.ngxLoader.stop();
  }

  questionBranchFilter(x: any): boolean {
    if (x.Branches == null) {
      return true;
    }
    const questionBranchIDs = JSON.parse(x.Branches).ids;
    return questionBranchIDs.includes(this.branchSelect);
  }

  getQuestionFeedbackAnswer(questionID: number, raw: any): any {
    if (raw.some(x => x.id === questionID)) {
      return raw.find(x => x.id === questionID);
    } else {
      return '';
    }
  }

  yesNoText(raw: any): void {
    console.log(raw);
  }

  selectCsr(csrSelected): void {
    this.ngxLoader.start();
    this.isCsvButtonDisabled = true;
    // this.startDateFilter.setHours(0, 0, 0, 0);
    this.endDateFilter.setHours(24, 0, 0, 0);
    this.csrDisplayData = [];
    this.csvString = '';
    if (csrSelected === 'ALL') {
      this.dataService.getQuestions().subscribe(
        questionResponse => {
          this.questions = questionResponse;
          this.questions = this.questions.filter(x => {
            return this.questionBranchFilter(x);
          });
          this.csvString = 'csr_name,';
          const questionsCount = this.questions.length;
          this.dataService.getAllFrontlinersByBranch(56, this.branchSelect).subscribe(
            csrResponse => {
              this.csrData = csrResponse;
              this.dataService.getCSRSurveyByBranch
              (this.branchSelect, moment(this.startDateFilter).utc().format('YYYY-MM-DD'), moment(this.endDateFilter).utc().format('YYYY-MM-DD')).subscribe(
                surveyResonse => {
                  this.surveyData = surveyResonse;
                  for (const csr of this.csrData) {
                    console.log(csr);
                    const csrFeedback = this.surveyData.filter(survey => survey.CSRName === csr.FRONTLINER_ID);
                    const csrDisplayObject = {
                      CSRName: csr.FULLNAME,
                      tally: [],
                      raw: []
                    };
                    for (const feedback of csrFeedback) {
                      const feedbackArray = JSON.parse(feedback.Feedback);
                      csrDisplayObject.raw = csrFeedback;
                      for (const question of this.questions) {
                        const answer = feedbackArray.find(feedbackObject => feedbackObject.id === question.id);
                        if (answer != null) {
                          if (question.IsFreeForm > 0) {
                            if (csrDisplayObject.tally.length === 0) { // empty
                              if (question.IsFreeForm === 6 && answer.answer !== 2) {
                                csrDisplayObject.tally.push({
                                  id: question.id,
                                  question: question.Question,
                                  answer: [{
                                    rating: 1,
                                    count: 1,
                                    yesNo: [answer.answer] as string[]
                                  }],
                                  isFreeForm: question.IsFreeForm
                                });

                              } else {
                                csrDisplayObject.tally.push({
                                  id: question.id,
                                  question: question.Question,
                                  answer: [{
                                    rating: answer.answer,
                                    count: 1
                                  }],
                                  isFreeForm: question.IsFreeForm
                                });
                              }
                            } else if (!csrDisplayObject.tally.some(tallyElement => tallyElement.id === question.id)) { // new question
                              if (question.IsFreeForm === 6 && answer.answer !== 2) {
                                csrDisplayObject.tally.push({
                                  id: question.id,
                                  question: question.Question,
                                  answer: [{
                                    rating: 1,
                                    count: 1,
                                    yesNo: [answer.answer] as string[]
                                  }],
                                  isFreeForm: question.IsFreeForm
                                });

                              } else {  // YES Count
                                csrDisplayObject.tally.push({
                                  id: question.id,
                                  question: question.Question,
                                  answer: [{
                                    rating: answer.answer,
                                    count: 1
                                  }],
                                  isFreeForm: question.IsFreeForm
                                });

                              }
                            } else { // existing
                              if (question.IsFreeForm === 6 && answer.answer !== 2) {
                                const tempTally = csrDisplayObject.tally.find(tallyElement => tallyElement.id === question.id);
                                if (tempTally.answer.some(sumElement => sumElement.rating === 1)) {
                                  let tempSum;
                                  try {
                                    tempSum = tempTally.answer.find(sumElement => sumElement.rating === 1);
                                    tempSum.count++;
                                    if (typeof tempSum.yesNo === 'string') {
                                      tempSum.yesNo = [tempSum.yesNo, answer.answer];
                                    } else {
                                      tempSum.yesNo.push(answer.answer);
                                    }
                                  } catch (e) {
                                    // console.log(tempTally);
                                    console.log(e);
                                  }
                                } else {
                                  tempTally.answer.push({
                                    rating: 1,
                                    count: 1,
                                    yesNo: answer.answer
                                  });
                                }
                              } else {
                                const tempTally = csrDisplayObject.tally.find(tallyElement => tallyElement.id === question.id);
                                if (tempTally.answer.some(sumElement => sumElement.rating === answer.answer)) {
                                  const tempSum = tempTally.answer.find(sumElement => sumElement.rating === answer.answer);
                                  tempSum.count++;

                                } else {
                                  tempTally.answer.push({
                                    rating: answer.answer,
                                    count: 1
                                  });
                                }
                              }
                            }
                          } else {
                            if (csrDisplayObject.tally.length === 0) {
                              csrDisplayObject.tally.push({
                                id: question.id,
                                question: question.Question,
                                answer: [answer.answer],
                                isFreeForm: question.IsFreeForm
                              });
                            } else if (!csrDisplayObject.tally.some(tallyElement => tallyElement.id === question.id)) {
                              csrDisplayObject.tally.push({
                                id: question.id,
                                question: question.Question,
                                answer: [answer.answer],
                                isFreeForm: question.IsFreeForm
                              });
                            } else {
                              const tempTally = csrDisplayObject.tally.find(tallyElement => tallyElement.id === question.id);
                              tempTally.answer.push(answer.answer);
                            }
                          }
                        }
                      }
                    }
                    for (const raw of csrDisplayObject.raw) {
                      const rawObj = JSON.parse(raw.Feedback);
                      raw.Feedback = rawObj;
                    }
                    this.csrDisplayData.push(csrDisplayObject);
                  }
                  // console.log(this.csrDisplayData);
                  this.isCsvButtonDisabled = false;
                  this.getOverview();
                  this.ngxLoader.stop();
                },
                surveyError => {
                  console.log(surveyError);
                  this.ngxLoader.stop();
                });
            },
            err => {
              console.log(err);
              this.ngxLoader.stop();
            }
          );
        },
        errorLAST => {
          console.log(errorLAST);
          this.ngxLoader.stop();
        }
      );
    } else {
      this.dataService.getQuestions().subscribe(
        questionResponse => {
          this.questions = questionResponse;
          this.dataService.getCSRDetails(this.csrSelect, 56, this.branchSelect).subscribe(
            csrResponse => {
              this.csrData = csrResponse;
              this.dataService.getCSRSurveyByName(this.branchSelect, moment(this.startDateFilter).utc().format('YYYY-MM-DD'), moment(this.endDateFilter).utc().format('YYYY-MM-DD')).subscribe(
                surveyResonse => {
                  this.surveyData = surveyResonse;
                  const csrFeedback = this.surveyData.filter(survey => survey.CSRName === this.csrData.fla.FRONTLINER_ID);
                  const csrDisplayObject = {
                    CSRName: this.csrData.fla.FULLNAME,
                    tally: [],
                    raw: []
                  };
                  for (const feedback of csrFeedback) {
                    const feedbackArray = JSON.parse(feedback.Feedback);
                    csrDisplayObject.raw = csrFeedback;
                    for (const question of this.questions) {
                      const answer = feedbackArray.find(feedbackObject => feedbackObject.id === question.id);
                      if (answer != null) {
                        if (question.IsFreeForm > 0) {
                          if (csrDisplayObject.tally.length === 0) { // empty
                            if (question.IsFreeForm === 6 && answer.answer !== 2) {
                              csrDisplayObject.tally.push({
                                id: question.id,
                                question: question.Question,
                                answer: [{
                                  rating: 1,
                                  count: 1,
                                  yesNo: [answer.answer] as string[]
                                }],
                                isFreeForm: question.IsFreeForm
                              });

                            } else {
                              csrDisplayObject.tally.push({
                                id: question.id,
                                question: question.Question,
                                answer: [{
                                  rating: answer.answer,
                                  count: 1
                                }],
                                isFreeForm: question.IsFreeForm
                              });
                            }
                          } else if (!csrDisplayObject.tally.some(tallyElement => tallyElement.id === question.id)) { // new question
                            if (question.IsFreeForm === 6 && answer.answer !== 2) {
                              csrDisplayObject.tally.push({
                                id: question.id,
                                question: question.Question,
                                answer: [{
                                  rating: 1,
                                  count: 1,
                                  yesNo: [answer.answer] as string[]
                                }],
                                isFreeForm: question.IsFreeForm
                              });
                            } else {
                              csrDisplayObject.tally.push({
                                id: question.id,
                                question: question.Question,
                                answer: [{
                                  rating: answer.answer,
                                  count: 1
                                }],
                                isFreeForm: question.IsFreeForm
                              });
                            }
                          } else { // existing
                            if (question.IsFreeForm === 6 && answer.answer !== 2) {
                              const tempTally = csrDisplayObject.tally.find(tallyElement => tallyElement.id === question.id);
                              if (tempTally.answer.some(sumElement => sumElement.rating === 1)) {
                                const tempSum = tempTally.answer.find(sumElement => sumElement.rating === 1);
                                tempSum.count++;
                                if (typeof tempSum.yesNo === 'string') {
                                  tempSum.yesNo = [tempSum.yesNo, answer.answer];
                                } else {
                                  tempSum.yesNo.push(answer.answer);
                                }

                              } else {
                                tempTally.answer.push({
                                  rating: 1,
                                  count: 1,
                                  yesNo: answer.answer
                                });

                              }
                            } else {
                              const tempTally = csrDisplayObject.tally.find(tallyElement => tallyElement.id === question.id);
                              if (tempTally.answer.some(sumElement => sumElement.rating === answer.answer)) {
                                const tempSum = tempTally.answer.find(sumElement => sumElement.rating === answer.answer);
                                tempSum.count++;

                              } else {
                                tempTally.answer.push({
                                  rating: answer.answer,
                                  count: 1
                                });
                              }
                            }
                          }
                        } else {
                          if (csrDisplayObject.tally.length === 0) {
                            csrDisplayObject.tally.push({
                              id: question.id,
                              question: question.Question,
                              answer: [answer.answer],
                              isFreeForm: question.IsFreeForm
                            });
                          } else if (!csrDisplayObject.tally.some(tallyElement => tallyElement.id === question.id)) {
                            csrDisplayObject.tally.push({
                              id: question.id,
                              question: question.Question,
                              answer: [answer.answer],
                              isFreeForm: question.IsFreeForm
                            });
                          } else {
                            const tempTally = csrDisplayObject.tally.find(tallyElement => tallyElement.id === question.id);
                            tempTally.answer.push(answer.answer);
                          }
                        }
                      }
                    }
                  }
                  for (const raw of csrDisplayObject.raw) {
                    const rawObj = JSON.parse(raw.Feedback);
                    raw.Feedback = rawObj;
                  }
                  this.csrDisplayData.push(csrDisplayObject);
                  this.isCsvButtonDisabled = false;
                  this.ngxLoader.stop();
                },
                surveyError => {
                  console.log(surveyError);
                  this.ngxLoader.stop();
                });
            },
            err => {
              console.log(err);
              this.ngxLoader.stop();
            }
          );
        },
        errorLAST => {
          console.log(errorLAST);
          this.ngxLoader.stop();
        }
      );
    }
  }
}
