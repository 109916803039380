import { Component, OnInit } from '@angular/core';
import {DataService} from '../data.service';
import {SurveyService} from '../api/services/survey.service';


@Component({
  selector: 'app-edit-questions',
  templateUrl: './edit-questions.component.html',
  styleUrls: ['./edit-questions.component.scss']
})
export class EditQuestionsComponent implements OnInit {

  questions: any;

  questionTypes = [
    {label: '1 to 5 Rating', id: 5},
    {label: 'Yes/No Question', id: 2},
    {label: 'Free Form Question', id: 0},

  ];


  constructor(private dataService: SurveyService) { }


  ngOnInit(): void {
    this.dataService.apiSurveyGetallSurveyQuestionsGet().subscribe(
      response => {
        this.questions = response;
        this.questions.forEach((question: any) => {
          question.typeLabel = this.questionTypes.find((qt: any) => qt.id === question.IsFreeForm).label;
          question.editMode = false;
        });
        console.log(response);
      });
  }

  submitEditQuestion(id: string): void {
    const questionToEdit = this.questions.find((q: any) => q.GUID);
    this.dataService.apiSurveyEditQuestionPost(questionToEdit).subscribe(response => {
      console.log(response);
    });
  }

}
