import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {FeedbackTableComponent} from './feedback-table/feedback-table.component';
import {SurveyComponent} from './survey/survey.component';
import {SubmitSuccessComponent} from './submit-success/submit-success.component';
import {SomethingWentWrongComponent} from './something-went-wrong/something-went-wrong.component';
import {EditQuestionsComponent} from './edit-questions/edit-questions.component';

const routes: Routes = [
  {path: 'reports', component: FeedbackTableComponent},
  {path: 'edit-questions', component: EditQuestionsComponent},
  {path: '', component: SurveyComponent},
  {path: 'survey', component: SurveyComponent},
  {path: 'success', component: SubmitSuccessComponent},
  {path: 'something-wrong/:error', component: SomethingWentWrongComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
