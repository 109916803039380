/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CustomModels } from '../models/custom-models';
import { PushTokenModel } from '../models/push-token-model';
import { QappsPortalModels } from '../models/qapps-portal-models';
import { Survey } from '../models/survey';
import { SurveyQuestion } from '../models/survey-question';
import { TokenLog } from '../models/token-log';

@Injectable({
  providedIn: 'root',
})
export class SurveyService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSurveyGetallSurveyGet
   */
  static readonly ApiSurveyGetallSurveyGetPath = '/api/Survey/GetallSurvey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyGetallSurveyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Survey>>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyGetallSurveyGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Survey>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyGetallSurveyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyGet(params?: {
  }): Observable<Array<Survey>> {

    return this.apiSurveyGetallSurveyGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Survey>>) => r.body as Array<Survey>)
    );
  }

  /**
   * Path part for operation apiSurveyGetallSurveyDatesGet
   */
  static readonly ApiSurveyGetallSurveyDatesGetPath = '/api/Survey/GetallSurveyDates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyGetallSurveyDatesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyDatesGet$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<Array<Survey>>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyGetallSurveyDatesGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Survey>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyGetallSurveyDatesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyDatesGet(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<Array<Survey>> {

    return this.apiSurveyGetallSurveyDatesGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Survey>>) => r.body as Array<Survey>)
    );
  }

  /**
   * Path part for operation apiSurveyGetallSurveyByBranchGet
   */
  static readonly ApiSurveyGetallSurveyByBranchGetPath = '/api/Survey/GetallSurveyByBranch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyGetallSurveyByBranchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyByBranchGet$Response(params?: {
    branch?: number;
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<Array<Survey>>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyGetallSurveyByBranchGetPath, 'get');
    if (params) {
      rb.query('branch', params.branch, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Survey>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyGetallSurveyByBranchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyByBranchGet(params?: {
    branch?: number;
    startDate?: string;
    endDate?: string;
  }): Observable<Array<Survey>> {

    return this.apiSurveyGetallSurveyByBranchGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Survey>>) => r.body as Array<Survey>)
    );
  }

  /**
   * Path part for operation apiSurveyGetallSurveyByRegionGet
   */
  static readonly ApiSurveyGetallSurveyByRegionGetPath = '/api/Survey/GetallSurveyByRegion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyGetallSurveyByRegionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyByRegionGet$Response(params?: {
    region?: string;
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<Array<Survey>>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyGetallSurveyByRegionGetPath, 'get');
    if (params) {
      rb.query('region', params.region, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Survey>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyGetallSurveyByRegionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyByRegionGet(params?: {
    region?: string;
    startDate?: string;
    endDate?: string;
  }): Observable<Array<Survey>> {

    return this.apiSurveyGetallSurveyByRegionGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Survey>>) => r.body as Array<Survey>)
    );
  }

  /**
   * Path part for operation apiSurveyGetallSurveyByIdGet
   */
  static readonly ApiSurveyGetallSurveyByIdGetPath = '/api/Survey/GetallSurveyByID';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyGetallSurveyByIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyByIdGet$Response(params?: {
    CSRName?: string;
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<Array<Survey>>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyGetallSurveyByIdGetPath, 'get');
    if (params) {
      rb.query('CSRName', params.CSRName, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Survey>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyGetallSurveyByIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyByIdGet(params?: {
    CSRName?: string;
    startDate?: string;
    endDate?: string;
  }): Observable<Array<Survey>> {

    return this.apiSurveyGetallSurveyByIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Survey>>) => r.body as Array<Survey>)
    );
  }

  /**
   * Path part for operation apiSurveyGetallSurveyMonthlyByBranchGet
   */
  static readonly ApiSurveyGetallSurveyMonthlyByBranchGetPath = '/api/Survey/GetallSurveyMonthlyByBranch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyGetallSurveyMonthlyByBranchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyMonthlyByBranchGet$Response(params?: {
    year?: number;
    company_id?: number;
  }): Observable<StrictHttpResponse<Array<QappsPortalModels>>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyGetallSurveyMonthlyByBranchGetPath, 'get');
    if (params) {
      rb.query('year', params.year, {});
      rb.query('company_id', params.company_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QappsPortalModels>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyGetallSurveyMonthlyByBranchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyMonthlyByBranchGet(params?: {
    year?: number;
    company_id?: number;
  }): Observable<Array<QappsPortalModels>> {

    return this.apiSurveyGetallSurveyMonthlyByBranchGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QappsPortalModels>>) => r.body as Array<QappsPortalModels>)
    );
  }

  /**
   * Path part for operation apiSurveyGetallSurveyWeeklyByBranchGet
   */
  static readonly ApiSurveyGetallSurveyWeeklyByBranchGetPath = '/api/Survey/GetallSurveyWeeklyByBranch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyGetallSurveyWeeklyByBranchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyWeeklyByBranchGet$Response(params?: {
    year?: number;
    company_id?: number;
  }): Observable<StrictHttpResponse<Array<QappsPortalModels>>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyGetallSurveyWeeklyByBranchGetPath, 'get');
    if (params) {
      rb.query('year', params.year, {});
      rb.query('company_id', params.company_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QappsPortalModels>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyGetallSurveyWeeklyByBranchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyWeeklyByBranchGet(params?: {
    year?: number;
    company_id?: number;
  }): Observable<Array<QappsPortalModels>> {

    return this.apiSurveyGetallSurveyWeeklyByBranchGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QappsPortalModels>>) => r.body as Array<QappsPortalModels>)
    );
  }

  /**
   * Path part for operation apiSurveyGetallSurveyTodayByBranchGet
   */
  static readonly ApiSurveyGetallSurveyTodayByBranchGetPath = '/api/Survey/GetallSurveyTodayByBranch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyGetallSurveyTodayByBranchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyTodayByBranchGet$Response(params?: {
    company_id?: number;
  }): Observable<StrictHttpResponse<Array<QappsPortalModels>>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyGetallSurveyTodayByBranchGetPath, 'get');
    if (params) {
      rb.query('company_id', params.company_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QappsPortalModels>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyGetallSurveyTodayByBranchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyTodayByBranchGet(params?: {
    company_id?: number;
  }): Observable<Array<QappsPortalModels>> {

    return this.apiSurveyGetallSurveyTodayByBranchGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QappsPortalModels>>) => r.body as Array<QappsPortalModels>)
    );
  }

  /**
   * Path part for operation apiSurveyGetallSurveyMonthlyByRegionGet
   */
  static readonly ApiSurveyGetallSurveyMonthlyByRegionGetPath = '/api/Survey/GetallSurveyMonthlyByRegion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyGetallSurveyMonthlyByRegionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyMonthlyByRegionGet$Response(params?: {
    year?: number;
    company_id?: number;
  }): Observable<StrictHttpResponse<Array<QappsPortalModels>>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyGetallSurveyMonthlyByRegionGetPath, 'get');
    if (params) {
      rb.query('year', params.year, {});
      rb.query('company_id', params.company_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QappsPortalModels>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyGetallSurveyMonthlyByRegionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyMonthlyByRegionGet(params?: {
    year?: number;
    company_id?: number;
  }): Observable<Array<QappsPortalModels>> {

    return this.apiSurveyGetallSurveyMonthlyByRegionGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QappsPortalModels>>) => r.body as Array<QappsPortalModels>)
    );
  }

  /**
   * Path part for operation apiSurveyGetallSurveyWeeklyByRegionGet
   */
  static readonly ApiSurveyGetallSurveyWeeklyByRegionGetPath = '/api/Survey/GetallSurveyWeeklyByRegion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyGetallSurveyWeeklyByRegionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyWeeklyByRegionGet$Response(params?: {
    year?: number;
    company_id?: number;
  }): Observable<StrictHttpResponse<Array<QappsPortalModels>>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyGetallSurveyWeeklyByRegionGetPath, 'get');
    if (params) {
      rb.query('year', params.year, {});
      rb.query('company_id', params.company_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QappsPortalModels>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyGetallSurveyWeeklyByRegionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyWeeklyByRegionGet(params?: {
    year?: number;
    company_id?: number;
  }): Observable<Array<QappsPortalModels>> {

    return this.apiSurveyGetallSurveyWeeklyByRegionGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QappsPortalModels>>) => r.body as Array<QappsPortalModels>)
    );
  }

  /**
   * Path part for operation apiSurveyGetallSurveyTodayByRegionGet
   */
  static readonly ApiSurveyGetallSurveyTodayByRegionGetPath = '/api/Survey/GetallSurveyTodayByRegion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyGetallSurveyTodayByRegionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyTodayByRegionGet$Response(params?: {
    company_id?: number;
  }): Observable<StrictHttpResponse<Array<QappsPortalModels>>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyGetallSurveyTodayByRegionGetPath, 'get');
    if (params) {
      rb.query('company_id', params.company_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QappsPortalModels>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyGetallSurveyTodayByRegionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyTodayByRegionGet(params?: {
    company_id?: number;
  }): Observable<Array<QappsPortalModels>> {

    return this.apiSurveyGetallSurveyTodayByRegionGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QappsPortalModels>>) => r.body as Array<QappsPortalModels>)
    );
  }

  /**
   * Path part for operation apiSurveyAddSurveyPost
   */
  static readonly ApiSurveyAddSurveyPostPath = '/api/Survey/AddSurvey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyAddSurveyPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSurveyAddSurveyPost$Response(params?: {
    body?: Survey
  }): Observable<StrictHttpResponse<Survey>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyAddSurveyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Survey>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyAddSurveyPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSurveyAddSurveyPost(params?: {
    body?: Survey
  }): Observable<Survey> {

    return this.apiSurveyAddSurveyPost$Response(params).pipe(
      map((r: StrictHttpResponse<Survey>) => r.body as Survey)
    );
  }

  /**
   * Path part for operation apiSurveyAddQuestionPost
   */
  static readonly ApiSurveyAddQuestionPostPath = '/api/Survey/AddQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyAddQuestionPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSurveyAddQuestionPost$Response(params?: {
    body?: SurveyQuestion
  }): Observable<StrictHttpResponse<SurveyQuestion>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyAddQuestionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SurveyQuestion>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyAddQuestionPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSurveyAddQuestionPost(params?: {
    body?: SurveyQuestion
  }): Observable<SurveyQuestion> {

    return this.apiSurveyAddQuestionPost$Response(params).pipe(
      map((r: StrictHttpResponse<SurveyQuestion>) => r.body as SurveyQuestion)
    );
  }

  /**
   * Path part for operation apiSurveyEditQuestionPost
   */
  static readonly ApiSurveyEditQuestionPostPath = '/api/Survey/EditQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyEditQuestionPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSurveyEditQuestionPost$Response(params?: {
    body?: SurveyQuestion
  }): Observable<StrictHttpResponse<SurveyQuestion>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyEditQuestionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SurveyQuestion>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyEditQuestionPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSurveyEditQuestionPost(params?: {
    body?: SurveyQuestion
  }): Observable<SurveyQuestion> {

    return this.apiSurveyEditQuestionPost$Response(params).pipe(
      map((r: StrictHttpResponse<SurveyQuestion>) => r.body as SurveyQuestion)
    );
  }

  /**
   * Path part for operation apiSurveyGetallSurveyQuestionsGet
   */
  static readonly ApiSurveyGetallSurveyQuestionsGetPath = '/api/Survey/GetallSurveyQuestions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyGetallSurveyQuestionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyQuestionsGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<SurveyQuestion>>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyGetallSurveyQuestionsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SurveyQuestion>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyGetallSurveyQuestionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetallSurveyQuestionsGet(params?: {
  }): Observable<Array<SurveyQuestion>> {

    return this.apiSurveyGetallSurveyQuestionsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SurveyQuestion>>) => r.body as Array<SurveyQuestion>)
    );
  }

  /**
   * Path part for operation apiSurveyUniversalPostPost
   */
  static readonly ApiSurveyUniversalPostPostPath = '/api/Survey/UniversalPOST';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyUniversalPostPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSurveyUniversalPostPost$Response(params?: {
    body?: CustomModels
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyUniversalPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyUniversalPostPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSurveyUniversalPostPost(params?: {
    body?: CustomModels
  }): Observable<void> {

    return this.apiSurveyUniversalPostPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSurveyGetLocalIpAddressGet
   */
  static readonly ApiSurveyGetLocalIpAddressGetPath = '/api/Survey/GetLocalIPAddress';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyGetLocalIpAddressGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetLocalIpAddressGet$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyGetLocalIpAddressGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyGetLocalIpAddressGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyGetLocalIpAddressGet(params?: {
  }): Observable<void> {

    return this.apiSurveyGetLocalIpAddressGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSurveyUniversalGetGet
   */
  static readonly ApiSurveyUniversalGetGetPath = '/api/Survey/UniversalGET';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyUniversalGetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyUniversalGetGet$Response(params?: {
    url?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyUniversalGetGetPath, 'get');
    if (params) {
      rb.query('url', params.url, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyUniversalGetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSurveyUniversalGetGet(params?: {
    url?: string;
  }): Observable<void> {

    return this.apiSurveyUniversalGetGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSurveyPushTokenPost
   */
  static readonly ApiSurveyPushTokenPostPath = '/api/Survey/PushToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSurveyPushTokenPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSurveyPushTokenPost$Response(params?: {
    body?: PushTokenModel
  }): Observable<StrictHttpResponse<TokenLog>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyService.ApiSurveyPushTokenPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenLog>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSurveyPushTokenPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSurveyPushTokenPost(params?: {
    body?: PushTokenModel
  }): Observable<TokenLog> {

    return this.apiSurveyPushTokenPost$Response(params).pipe(
      map((r: StrictHttpResponse<TokenLog>) => r.body as TokenLog)
    );
  }

}
