<ng-template #successContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Thank you.</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Survey Submitted! Thank you for taking your time to answer our survey.
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark btn-block btn-lg"
      (click)="modal.close('Save click')">Okay</button>
  </div>
</ng-template>

<form [formGroup]="surveyForm" (ngSubmit)="onSubmit()">
  <div class="side-padding">
    <div class=" mt-3">
      <div class="row">
<!--        <div class="col-12 col-md-3 mb-1">-->
<!--          <div>-->
<!--            <div>-->
<!--              <b class="h6 small font-weight-normal">Service Order</b>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div>-->
<!--            <div class="form-group">-->
<!--              <input type="text" class="form-control" formControlName="orderNumber" id="orderNumber"-->
<!--                aria-describedby="helpId" placeholder="">-->
<!--              <div *ngIf="submitted && sf.orderNumber.errors">-->
<!--                <div *ngIf="sf.orderNumber.errors.required" class="error">Service Order Number is required</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="col-12 col-md-3 mb-1">
          <div>
            <div>
              <b class="h6 small font-weight-normal" style="color:  #007BFF">Visitor Name</b>
            </div>
          </div>
          <div>
            <div class="form-group">
              <div class="">
                <div class="input-group mb-2">
<!--                  <div class="input-group-prepend">-->
<!--                    <div class="input-group-text">+63</div>-->
<!--                  </div>-->
                  <input type="text" class="form-control"  formControlName="visitorName" id="visitorName"
                     [maxLength]="50" aria-describedby="helpId" placeholder="">
                </div>
              </div>
              <div *ngIf="submitted && sf.visitorName.errors">
                <div *ngIf="sf.visitorName.errors.required" class="error">Visitor Name is required.</div>
<!--                <div *ngIf="sf.mobileNumber.errors.minlength" class="error">Visitor Name should be 10 digits.</div>-->
                <div *ngIf="sf.visitorName.errors.maxlength" class="error">Visitor Name should can only be 50 characters.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-0">
        <div class="col-6 col-md-3 mb-0">
          <div>
            <div>
              <b class="h6 small font-weight-normal" style="color:  #007BFF">Visit Date</b>
            </div>
          </div>
          <div>
            <p class="font-weight-bolder">{{dateToday | date}}</p>
          </div>
        </div>
        <div class="col-6 col-md-3 mb-1">
          <div>
            <div>
              <b class="h6 small font-weight-normal" style="color:  #007BFF">Project and Unit/Parking</b>
            </div>
          </div>
          <div>
            <p class="font-weight-bolder">{{BranchName}}</p>
          </div>
        </div>
        <div class="col-6 col-md-3 mb-1">
          <div>
            <div>
              <b class="h6 small font-weight-normal" style="color:  #007BFF">Assisted by</b>
            </div>
          </div>
          <div>
            <p class="font-weight-bolder mb-0 pb-0">{{CSRName}}</p>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-3">
    <div class="mt-1">
      <div class="row">
        <div class="col-lg">
          <p>
            We are committed to providing you with the best customer experience possible, so we welcome your comments. Kindly answer this short survey. Thank you.
          </p>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <div class="row">
        <div class="col-md-12 col-sm-12 mb-3" *ngFor="let question of questions; index as i">
          <div class="card">
            <div class="card-body">
              <p [innerHTML]="question.Question" ></p>
              <!-- <br> -->
              <div *ngIf="question.IsFreeForm === 5">
                <div *ngIf="question.IsFreeForm === 5" class="btn-group btn-group-toggle btn-group-custom" ngbRadioGroup name="radioBasic" [ngModelOptions]="{standalone: true}" [(ngModel)]="answers[i].answer" >
                  <label ngbButtonLabel class="radio-custom btn-outline-danger">
                    <input ngbButton type="radio" [value]="-2"> 1
                  </label>
                  <label ngbButtonLabel class="radio-custom btn-outline-dark">
                    <input ngbButton type="radio" [value]="-1"> 2
                  </label>
                  <label ngbButtonLabel class="radio-custom btn-outline-dark">
                    <input ngbButton type="radio" [value]="0"> 3
                  </label>
                  <label ngbButtonLabel class="radio-custom btn-outline-dark">
                    <input ngbButton type="radio" [value]="1"> 4
                  </label>
                  <label ngbButtonLabel class="radio-custom btn-outline-primary">
                    <input ngbButton type="radio" [value]="2"> 5
                  </label>
                </div>
                <div class="d-flex justify-content-between">
                  <small>Disappointing</small>
                  <small>Exceptional</small>
                </div>
              </div>


              <div *ngIf="question.IsFreeForm === 0">
                <textarea class="form-control" [ngModelOptions]="{standalone: true}" [maxLength]="500"
                  [(ngModel)]="answers[i].answer">
                </textarea>
                <div class="mt-2 text-right">
                  {{answers[i].answer.length}} / 500
                </div>
              </div>
              <div *ngIf="question.IsFreeForm === 2" class="btn-group btn-group-toggle btn-group-custom" ngbRadioGroup
                name="yesno" [ngModelOptions]="{standalone: true}" [(ngModel)]="answers[i].answer">
                <label ngbButtonLabel class="radio-custom btn-outline-primary">
                  <input ngbButton type="radio" [value]="2"> Yes
                </label>
                <label ngbButtonLabel class="radio-custom btn-outline-dark">
                  <input ngbButton type="radio" [value]="1"> No
                </label>
              </div>
              <div *ngIf="question.IsFreeForm === 6">
                <div class="btn-group btn-group-toggle btn-group-custom" ngbRadioGroup name="yesnowhy"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="answers[i].yesNo">
                  <label ngbButtonLabel class="radio-custom btn-outline-success">
                    <input ngbButton type="radio" [value]="2"> Yes
                  </label>
                  <label ngbButtonLabel class="radio-custom btn-outline-primary">
                    <input ngbButton type="radio" [value]="1"> No
                  </label>
                </div>

                <div *ngIf="answers[i].yesNo === 1" class="mt-3">
                  <textarea class="form-control" [ngModelOptions]="{standalone: true}" [maxLength]="500"
                    [(ngModel)]="answers[i].answer" placeholder="Why?">
                  </textarea>
                  <div class="mt-2 text-right">
                    {{answers[i].answer.length}} / 500
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center mb-4 mt-0 pt-0">
          <p>
            <ngb-alert [dismissible]="false" type="danger" *ngIf="showAlert">
              Kindly answer the whole survey.
            </ngb-alert>
          </p>
          <button class="btn btn-dark btn-block btn-lg" type="button" (click)="onSubmit()"> Submit </button>
        </div>
      </div>
    </div>

  </div>
</form>
