<table class="table">
  <thead>
  <tr>
<!--    <th scope="col">#</th>-->
    <th scope="col">Question</th>
    <th scope="col">Enabled</th>
    <th scope="col">Question Type</th>
    <th scope="col">Edit</th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngFor="let question of questions">
    <tr>
      <ng-container *ngIf="!question.editMode; else editMode">
        <!--      <th scope="row">1</th>-->
        <td [innerHTML]="question.Question"></td>
        <td>{{question.IsEnabled? 'Enabled' : 'Disabled'}}</td>
        <td>{{question.typeLabel}}</td>
        <td>
          <button type="button" class="btn btn-primary" (click)="question.editMode=!question.editMode">Edit</button>
        </td>
      </ng-container>
      <ng-template #editMode>
        <td>
          <input type="text" class="form-control" [(ngModel)]="question.Question"></td>
        <td>
          <input type="checkbox" [(ngModel)]="question.IsEnabled">
          {{question.IsEnabled? 'Enabled' : 'Disabled'}}
        </td>
        <td>
          <select [(ngModel)]="question.IsFreeForm">
            <option *ngFor="let questionType of questionTypes" [value]="questionType?.id"
                    [selected]="questionType.selected == true">{{questionType.label}}</option>
          </select>
        </td>
        <td>
          <button type="button" class="btn btn-primary" (click)="question.editMode=!question.editMode">Edit</button>
        </td>
      </ng-template>

    </tr>
  </ng-container>

  </tbody>
</table>
